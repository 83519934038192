import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_STUDY_DEFINEXML_LANG: {
  ADD_ROW_VERB: MvnCodeFE;
  PREVIEW_HTML_VERB: MvnCodeFE;

  PLACEHOLDER_AUTOMATION_VERB: MvnCodeFE;
  PLACEHOLDER_SELECTION_VERB: MvnCodeFE;
  PLACEHOLDER_METADATA_VERSION_VERB: MvnCodeFE;
  PLACEHOLDER_DESCRIPTION_VERB: MvnCodeFE;
  PLACEHOLDER_DICTIONARY_NAME_VERB: MvnCodeFE;
  PLACEHOLDER_VERSION_VERB: MvnCodeFE;
  PLACEHOLDER_STANDARD_VERSION_VERB: MvnCodeFE;
  PLACEHOLDER_FORMAT_VERB: MvnCodeFE;
  PLACEHOLDER_NUMERIC_VERB: MvnCodeFE;
  PLACEHOLDER_PAGE_NUMBER_VERB: MvnCodeFE;
  PLACEHOLDER_PREDECESSOR_VERB: MvnCodeFE;
  PLACEHOLDER_WHERE_CLAUSE_VERB: MvnCodeFE;
  PLACEHOLDER_LABEL_VERB: MvnCodeFE;
  PLACEHOLDER_METHOD_NAME_VERB: MvnCodeFE;
  PLACEHOLDER_METHOD_DESCRIPTION_VERB: MvnCodeFE;
  PLACEHOLDER_METHOD_CONTEXT_VERB: MvnCodeFE;
  PLACEHOLDER_METHOD_CODE_VERB: MvnCodeFE;
  PLACEHOLDER_COMMENT_VERB: MvnCodeFE;
  PLACEHOLDER_DOCUMENT_TITLE_VERB: MvnCodeFE;

  DELETE_DEFINEXML_ROW_SUCCEED: MvnCodeFE;
  DELETE_DEFINEXML_ROW_FAIL: MvnCodeFE;
  SAVE_DEFINEXML_ROW_SUCCEED: MvnCodeFE;
  SAVE_DEFINEXML_ROW_FAIL: MvnCodeFE;
  SAVE_DEFINEXML_ROW_FAIL_ON_DUPLICATED: MvnCodeFE;

  ADDABLE_EMPTY_DATA_DESCRIPTION: MvnCodeFE;
  ROW_CONTENT_RESET_DESCRIPTION: MvnCodeFE;
} = {
  ADD_ROW_VERB: {
    ko: "행 추가",
    en: "Add Row",
  },
  PREVIEW_HTML_VERB: {
    ko: "HTML 미리보기",
    en: "HTML Preview",
  },
  PLACEHOLDER_AUTOMATION_VERB: {
    ko: "자동 입력",
    en: "Automation",
  },
  PLACEHOLDER_SELECTION_VERB: {
    ko: "선택",
    en: "Select....",
  },
  PLACEHOLDER_METADATA_VERSION_VERB: {
    ko: "메타데이터 버전 입력",
    en: "Enter the metadata version",
  },
  PLACEHOLDER_DESCRIPTION_VERB: {
    ko: "기타 설명 입력",
    en: "Enter the description",
  },
  PLACEHOLDER_DICTIONARY_NAME_VERB: {
    ko: "예: MedDRA for Medical History",
    en: "e.g. MedDRA for Medical History",
  },
  PLACEHOLDER_VERSION_VERB: {
    ko: "버전 입력",
    en: "Enter the version",
  },
  PLACEHOLDER_STANDARD_VERSION_VERB: {
    ko: "버전 입력(예: SDTMIG v.3.4)",
    en: "Enter the standard and version(e.g. SDTMIG v3.4)",
  },
  PLACEHOLDER_FORMAT_VERB: {
    ko: "데이터 출력 형식 입력(예: Z2.)",
    en: "Enter the display format(e.g. Z2.)",
  },
  PLACEHOLDER_NUMERIC_VERB: {
    ko: "숫자 입력",
    en: "Number only",
  },
  PLACEHOLDER_PAGE_NUMBER_VERB: {
    ko: "페이지 번호 입력(컴마로 구분)",
    en: "Enter pages separated by commas",
  },
  PLACEHOLDER_PREDECESSOR_VERB: {
    ko: "선행 Variable Name",
    en: "Enter the Predecessor",
  },
  PLACEHOLDER_WHERE_CLAUSE_VERB: {
    ko: "Where 조건절 입력",
    en: "Enter the Where Clause",
  },
  PLACEHOLDER_LABEL_VERB: {
    ko: "VLM의 레이블 입력",
    en: "Enter the Method's Label",
  },
  PLACEHOLDER_METHOD_NAME_VERB: {
    ko: "Method 이름 입력",
    en: "Enter the Method name",
  },
  PLACEHOLDER_METHOD_DESCRIPTION_VERB: {
    ko: "Method 내용 입력",
    en: "Enter the description",
  },
  PLACEHOLDER_METHOD_CONTEXT_VERB: {
    ko: "Method 추가 설명 입력",
    en: "Enter the child Description",
  },
  PLACEHOLDER_METHOD_CODE_VERB: {
    ko: "코드화된 계산식 입력",
    en: "Enter the coded expression",
  },
  PLACEHOLDER_COMMENT_VERB: {
    ko: "Comment 입력",
    en: "Enter comment content",
  },
  PLACEHOLDER_DOCUMENT_TITLE_VERB: {
    ko: "문서명 입력",
    en: "Enter the document title",
  },
  DELETE_DEFINEXML_ROW_SUCCEED: {
    ko: "삭제했습니다.",
    en: "Deletion has been completed.",
  },
  DELETE_DEFINEXML_ROW_FAIL: {
    ko: "삭제에 실패했습니다.",
    en: "Failed to delete.",
  },

  SAVE_DEFINEXML_ROW_SUCCEED: {
    ko: "저장했습니다.",
    en: "Saving has been completed.",
  },
  SAVE_DEFINEXML_ROW_FAIL: {
    ko: "저장에 실패했습니다.",
    en: "Failed to save.",
  },
  SAVE_DEFINEXML_ROW_FAIL_ON_DUPLICATED: {
    ko: "중복행이 존재합니다.",
    en: "Duplicate rows exist.",
  },
  ADDABLE_EMPTY_DATA_DESCRIPTION: {
    ko: "버튼을 눌러 빈 행을 추가하세요.",
    en: "Press the button to add a record.",
  },
  ROW_CONTENT_RESET_DESCRIPTION: {
    ko: "기본 할당된 정보 외에 사용자가 입력한 내용이 초기화 됩니다.",
    en: "User-entered content will be reset except for the default assigned information.",
  },
};

MvnCodeInitiate(CONVERTER_STUDY_DEFINEXML_LANG);
