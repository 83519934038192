import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_STUDY_CRF_LANG: {
  EMPTY_DOCUMENT_TITLE: MvnCodeFE;
  EMPTY_DOCUMENT_DESCRIPTION: MvnCodeFE;
  DOCUMENT_IMPORT_SUCCEED: MvnCodeFE;
  DOCUMENT_IMPORT_FAIL: MvnCodeFE;
  DOCUMENT_EXPORT_SUCCEED: MvnCodeFE;
  DOCUMENT_EXPORT_FAIL: MvnCodeFE;
  DOCUMENT_SAVE_SUCCEED: MvnCodeFE;
  DOCUMENT_SAVE_FAIL: MvnCodeFE;
  IMPORT_VERB: MvnCodeFE;
  EXPORT_VERB: MvnCodeFE;
  CRF_SAVE_VERB: MvnCodeFE;
  ARROW_LEFT_VERB: MvnCodeFE;
  ARROW_RIGHT_VERB: MvnCodeFE;
  ARROW_TOP_VERB: MvnCodeFE;
  ARROW_BOTTOM_VERB: MvnCodeFE;
  DELETE_VERB: MvnCodeFE;
  NOT_SUBMITTED_DESCRIPTION: MvnCodeFE;
  COLOR_PICKER_TITLE: MvnCodeFE;
  COLOR_PICKER_DESCRIPTION: MvnCodeFE;
} = {
  EMPTY_DOCUMENT_TITLE: {
    ko: "불러온 문서가 없습니다.",
    en: "There is no document.",
  },
  EMPTY_DOCUMENT_DESCRIPTION: {
    ko: "버튼을 눌러 파일을 생성하세요.",
    en: "Click the button to create a file.",
  },

  DOCUMENT_IMPORT_SUCCEED: {
    ko: "가져오기를 완료했습니다.",
    en: "Import has been completed.",
  },
  DOCUMENT_IMPORT_FAIL: {
    ko: "가져오기에 실패했습니다.",
    en: "Failed to Import.",
  },
  DOCUMENT_EXPORT_SUCCEED: {
    ko: "내보내기를 완료했습니다.",
    en: "Export has been completed.",
  },
  DOCUMENT_EXPORT_FAIL: {
    ko: "내보내기에 실패했습니다.",
    en: "Failed to export.",
  },
  DOCUMENT_SAVE_SUCCEED: {
    ko: "aCRF를 저장했습니다.",
    en: "aCRF has been saved successfully.",
  },
  DOCUMENT_SAVE_FAIL: {
    ko: "aCRF 저장에 실패했습니다.",
    en: "Failed to save aCRF.",
  },

  IMPORT_VERB: {
    ko: "가져오기",
    en: "Import",
  },
  EXPORT_VERB: {
    ko: "내보내기",
    en: "Export",
  },
  CRF_SAVE_VERB: {
    ko: "aCRF 저장하기",
    en: "aCRF Save",
  },

  ARROW_LEFT_VERB: {
    ko: "왼쪽 화살표",
    en: "Add left arrow",
  },
  ARROW_RIGHT_VERB: {
    ko: "오른쪽 화살표",
    en: "Add right arrow",
  },
  ARROW_TOP_VERB: {
    ko: "위쪽 화살표",
    en: "Add top arrow",
  },
  ARROW_BOTTOM_VERB: {
    ko: "아래쪽 화살표",
    en: "Add bottom arrow",
  },
  DELETE_VERB: {
    ko: "삭제",
    en: "Delete",
  },
  NOT_SUBMITTED_DESCRIPTION: {
    ko: "미체출 표기용 라벨",
    en: "Label for unsubmitted.",
  },

  COLOR_PICKER_TITLE: {
    ko: "항목당 1개 색상을 각각 선택해 주세요.",
    en: "Please select one color for each item.",
  },
  COLOR_PICKER_DESCRIPTION: {
    ko: "단, 동일 페이지 안에서 여러 데이터 세트의 색상이 서로 충돌할 경우, 나중에 라벨링한 데이터 세트의 두 번째 색상이 사용됩니다.",
    en: "However, if the primary colors of the datasets conflict on the same page, the dataset selected later will use its color as the secondary color.",
  },
};

MvnCodeInitiate(CONVERTER_STUDY_CRF_LANG);
