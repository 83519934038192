import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_SUBJECT_AUDIT_LANG: {
  CDMS_STUDY_SUBJECT_AUDIT_CREATE_GROUP: MvnCodeFE;
  CDMS_STUDY_SUBJECT_AUDIT_UPDATE_GROUP: MvnCodeFE;
} = {
  CDMS_STUDY_SUBJECT_AUDIT_CREATE_GROUP: {
    ko: "Group 지정",
    en: "Assign Group",
  },
  CDMS_STUDY_SUBJECT_AUDIT_UPDATE_GROUP: {
    ko: "Group 변경",
    en: "Change Group",
  },
};

MvnCodeInitiate(CDMS_STUDY_SUBJECT_AUDIT_LANG);
