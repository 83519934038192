import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_STUDY_DATASET_LANG: {
  RESET_TARGET_DATASETS_DESCRIPTION: MvnCodeFE;
  RESET_TARGET_DATASETS_SUCCEED: MvnCodeFE;
  RESET_TARGET_DATASETS_FAIL: MvnCodeFE;
  CREATE_CANDIDATE_DATASET_SUCCEED: MvnCodeFE;
  CREATE_CANDIDATE_DATASET_FAIL: MvnCodeFE;
  DELETE_CANDIDATE_DATASET_SUCCEED: MvnCodeFE;
  DELETE_CANDIDATE_DATASET_FAIL: MvnCodeFE;
  CREATE_CANDIDATE_SUPP_DATASET_SUCCEED: MvnCodeFE;
  CREATE_CANDIDATE_SUPP_DATASET_FAIL: MvnCodeFE;
  CREATE_TARGET_DATASET_SUCCEED: MvnCodeFE;
  CREATE_TARGET_DATASET_FAIL: MvnCodeFE;
  DELETE_TARGET_DATASET_DESCRIPTION: MvnCodeFE;
  DELETE_TARGET_DATASET_SUCCEED: MvnCodeFE;
  DELETE_TARGET_DATASET_FAIL: MvnCodeFE;
  VARIABLE_ORDER_EDIT_SUCCEED: MvnCodeFE;
  VARIABLE_ORDER_EDIT_FAIL: MvnCodeFE;
  CREATE_CUSTOM_DOMAIN_DATASET_TITLE: MvnCodeFE;
  CREATE_TARGET_DATASET_TITLE: MvnCodeFE;
  CANDIDATE_DATASETS_TITLE: MvnCodeFE;
  TARGET_DATASETS_TITLE: MvnCodeFE;
  RESET_TITLE: MvnCodeFE;
  EDIT_MODE_ACTIVE_TITLE: MvnCodeFE;
  EDIT_MODE_DEACTIVE_TITLE: MvnCodeFE;
  KEY_TITLE: MvnCodeFE;
  KEY_RESET_TITLE: MvnCodeFE;
  KEY_ORDER_EDIT_TITLE: MvnCodeFE;
  ADD_VARIABLE_TITLE: MvnCodeFE;
  ADD_VARIABLE_TITLE_DESCRIPTION: MvnCodeFE;
  KEY_ORDER_EDIT_SUCCEED: MvnCodeFE;
  KEY_ORDER_EDIT_FAIL: MvnCodeFE;
  KEY_RESET_SUCCEED: MvnCodeFE;
  KEY_RESET_FAIL: MvnCodeFE;
  CREATE_TARGET_VARIABLE_KEY_SUCCEED: MvnCodeFE;
  CREATE_TARGET_VARIABLE_KEY_FAIL: MvnCodeFE;
  DELETE_TARGET_VARIABLE_KEY_SUCCEED: MvnCodeFE;
  DELETE_TARGET_VARIABLE_KEY_FAIL: MvnCodeFE;
  DELETE_TARGET_VARIABLE_DESCRIPTION: MvnCodeFE;
  DELETE_TARGET_VARIABLE_SUCCEED: MvnCodeFE;
  DELETE_TARGET_VARIABLE_FAIL: MvnCodeFE;
  CREATE_TARGET_VARIABLE_SUCCEED: MvnCodeFE;
  CREATE_TARGET_VARIABLE_FAIL: MvnCodeFE;
  PLACEHOLDER_DOMAIN_NAME: MvnCodeFE;
  PLACEHOLDER_DOMAIN_LABEL: MvnCodeFE;
  PLACEHOLDER_STRUCTURE: MvnCodeFE;
  PLACEHOLDER_DATASET_NAME: MvnCodeFE;
  PLACEHOLDER_DATASET_LABEL: MvnCodeFE;
} = {
  RESET_TARGET_DATASETS_DESCRIPTION: {
    ko: "현재 구성된 모든 설정이 삭제되고 초기화 됩니다.\n초기화를 진행하시겠습니까?",
    en: "All currently configured settings will be deleted and reset to their default values.\nDo you wish to proceed with the reset?",
  },
  RESET_TARGET_DATASETS_SUCCEED: {
    ko: "초기화를 완료했습니다.",
    en: "Initialization has been completed.",
  },
  RESET_TARGET_DATASETS_FAIL: {
    ko: "초기화에 실패했습니다.",
    en: "Failed to initialize.",
  },
  CREATE_CANDIDATE_DATASET_SUCCEED: {
    ko: "도메인 & 데이터 세트 생성을 완료했습니다.",
    en: "Custom Domain & Dataset creation has been completed.",
  },
  CREATE_CANDIDATE_DATASET_FAIL: {
    ko: "도메인 & 데이터 세트 생성에 실패했습니다.",
    en: "Failed to create the Custom Domain & Dataset.",
  },
  DELETE_CANDIDATE_DATASET_SUCCEED: {
    ko: "도메인 & 데이터 세트를 삭제했습니다.",
    en: "The domain and dataset have been deleted.",
  },
  DELETE_CANDIDATE_DATASET_FAIL: {
    ko: "도메인 & 데이터 세트 삭제에 실패했습니다.",
    en: "Failed to delete the domain and dataset.",
  },
  CREATE_CANDIDATE_SUPP_DATASET_SUCCEED: {
    ko: "{name} 가(이) 추가 되었습니다.",
    en: "The {name} has been added.",
  },
  CREATE_CANDIDATE_SUPP_DATASET_FAIL: {
    ko: "도메인 & 데이터 세트 생성에 실패했습니다.",
    en: "Failed to add {name}",
  },
  CREATE_TARGET_DATASET_SUCCEED: {
    ko: "{name} 을(를) 대상 데이터 세트로 추가했습니다.",
    en: "{name} has been added as the target dataset.",
  },
  CREATE_TARGET_DATASET_FAIL: {
    ko: "대상 데이터 세트 추가에 실패했습니다.",
    en: "Failed to add the target dataset.",
  },
  DELETE_TARGET_DATASET_DESCRIPTION: {
    ko: "{name} 을(를) 삭제하겠습니까?",
    en: "Are you sure you want to delete {name}?",
  },
  DELETE_TARGET_DATASET_SUCCEED: {
    ko: "대상 데이터 세트를 삭제했습니다.",
    en: "The target dataset has been deleted.",
  },
  DELETE_TARGET_DATASET_FAIL: {
    ko: "대상 데이터 세트 삭제에 실패했습니다.",
    en: "Failed to delete the target dataset.",
  },

  RESET_TITLE: { ko: "초기화", en: "Reset" },
  EDIT_MODE_ACTIVE_TITLE: { ko: "수정모드", en: "Edit Mode" },
  EDIT_MODE_DEACTIVE_TITLE: { ko: "수정모드 종료", en: "Exit Edit Mode" },
  KEY_TITLE: { ko: "키", en: "Key" },
  KEY_RESET_TITLE: { ko: "키 초기화", en: "Reset" },
  KEY_ORDER_EDIT_TITLE: { ko: "키 변수 순서 조정", en: "Rearrange Key Order" },

  CREATE_CUSTOM_DOMAIN_DATASET_TITLE: { ko: "사용자 생성 도메인 & 데이터 세트 추가", en: "Add Custom Domain & Dataset" },
  CANDIDATE_DATASETS_TITLE: { ko: "후보 데이터 세트", en: "Candidate Datasets" },
  TARGET_DATASETS_TITLE: { ko: "대상 데이터 세트", en: "Target Datasets" },

  CREATE_TARGET_DATASET_TITLE: { ko: "대상 데이터 세트에 추가", en: "Add to Target Datasets" },

  ADD_VARIABLE_TITLE: { ko: "변수 추가", en: "Add Variable(s)" },
  ADD_VARIABLE_TITLE_DESCRIPTION: {
    ko: "추가할 변수를 체크해 주세요.",
    en: "Select the variable you wish to add.",
  },

  VARIABLE_ORDER_EDIT_SUCCEED: {
    ko: "변수의 순서 적용을 완료했습니다.",
    en: "Variable order application has been completed.",
  },
  VARIABLE_ORDER_EDIT_FAIL: {
    ko: "변수의 순서 적용에 실패했습니다.",
    en: "Failed to apply variable order.",
  },
  KEY_ORDER_EDIT_SUCCEED: {
    ko: "키 변수의 순서 적용을 완료했습니다.",
    en: "Key order application has been completed.",
  },
  KEY_ORDER_EDIT_FAIL: {
    ko: "키 변수의 순서 적용에 실패했습니다.",
    en: "Failed to apply key order.",
  },

  KEY_RESET_SUCCEED: {
    ko: "키 초기화를 완료했습니다.",
    en: "The key has been initialized.",
  },
  KEY_RESET_FAIL: {
    ko: "키 초기화에 실패했습니다.",
    en: "Failed to initialize the key.",
  },

  CREATE_TARGET_VARIABLE_KEY_SUCCEED: {
    ko: "{name}을(를) 키 변수로 추가하였습니다.",
    en: "{name}을(를) 키 변수로 추가하였습니다.", // TODO : 영문 번역 필요
  },
  CREATE_TARGET_VARIABLE_KEY_FAIL: {
    ko: "키 변수 추가에 실패 하였습니다.",
    en: "키 변수 추가에 실패 하였습니다.", // TODO : 영문 번역 필요
  },
  DELETE_TARGET_VARIABLE_KEY_SUCCEED: {
    ko: "{name}을(를) 키 변수에서 제거하였습니다.",
    en: "{name}을(를) 키 변수에서 제거하였습니다.", // TODO : 영문 번역 필요
  },
  DELETE_TARGET_VARIABLE_KEY_FAIL: {
    ko: "키 변수 제거에 실패 하였습니다.",
    en: "키 변수 제거에 실패 하였습니다.", // TODO : 영문 번역 필요
  },
  DELETE_TARGET_VARIABLE_DESCRIPTION: {
    ko: "{name}을(를) 삭제하겠습니까?",
    en: "Would you like to confirm the deletion of {name}?",
  },
  DELETE_TARGET_VARIABLE_SUCCEED: {
    ko: "변수를 삭제했습니다.",
    en: "Variable has been deleted.",
  },
  DELETE_TARGET_VARIABLE_FAIL: {
    ko: "변수 삭제에 실패했습니다.",
    en: "Failed to delete variable.",
  },

  CREATE_TARGET_VARIABLE_SUCCEED: {
    ko: "변수를 추가했습니다.",
    en: "Variable(s) has(have) been added.",
  },
  CREATE_TARGET_VARIABLE_FAIL: {
    ko: "변수 추가에 실패했습니다.",
    en: "Failed to add Variable(s).",
  },

  PLACEHOLDER_DOMAIN_NAME: { ko: "Domain 이름 입력 또는 선택", en: "Select or Enter the Domain name" },
  PLACEHOLDER_DOMAIN_LABEL: { ko: "Domain 레이블 입력", en: "Enter the domain label" },
  PLACEHOLDER_STRUCTURE: { ko: "Structure 입력", en: "Enter the structure" },
  PLACEHOLDER_DATASET_NAME: { ko: "Dataset 이름 입력", en: "Enter the Dataset name" },
  PLACEHOLDER_DATASET_LABEL: { ko: "Dataset 레이블 입력", en: "Enter the Dataset label" },
};

MvnCodeInitiate(CONVERTER_STUDY_DATASET_LANG);
