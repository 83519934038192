import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_SUBJECT_GROUPING_LANG: {
  CDMS_SUBJECT_GROUPING: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_DESC: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_ADD_DIALOG_TITLE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_ADD_SUCCESS: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_ADD_FAIL: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_ADD_MAXIMUM: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_EDIT_DIALOG_TITLE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_EDIT_SUCCESS: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_EDIT_FAIL: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_DELETE_DIALOG_TITLE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_DELETE_DIALOG_DESC: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_DELETE_SUCCESS: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_DELETE_FAIL: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_NO_DELETE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_COLUMN_ID_LABEL: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_COLUMN_ID_PLACEHOLDER: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_COLUMN_LANG_LABEL: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_COLUMN_LANG_REQUIRED: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_COLUMN_LANG_PLACEHOLDER: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_VALUE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_VALUE_DB_PLACEHOLDER: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_VALUE_UI_PLACEHOLDER: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_VALUE_NO_DELETE: MvnCodeFE;
  CDMS_SUBJECT_ASSIGN_GROUPING_SUCCESS: MvnCodeFE;
  CDMS_SUBJECT_ASSIGN_GROUPING_FAIL: MvnCodeFE;
  CDMS_SUBJECT_ASSIGN_GROUPING_TITLE: MvnCodeFE;
  CDMS_SUBJECT_ASSIGN_GROUPING_SITE_NAME_LABEL: MvnCodeFE;
  CDMS_SUBJECT_ASSIGN_GROUPING_SUBJECT_NO_LABEL: MvnCodeFE;
  CDMS_SUBJECT_ASSIGN_GROUPING_COLUMN_LABEL: MvnCodeFE;
  CDMS_SUBJECT_ASSIGN_GROUPING_NOT_SELECTED_VALUE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_TITLE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_CREATE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_DELETE: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_UPDATE_ID: MvnCodeFE;
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_UPDATE_LABEL: MvnCodeFE;
} = {
  CDMS_SUBJECT_GROUPING: {
    ko: "Subject Grouping",
    en: "Subject Grouping",
  },
  CDMS_SUBJECT_GROUPING_DESC: {
    ko: "대상자에 지정할 수 있는 그룹과 값을 설정하고 관리할 수 있습니다.",
    en: "Manage the groups and values that can be assigned to subjects.",
  },
  CDMS_SUBJECT_GROUPING_ADD_DIALOG_TITLE: {
    ko: "Add Group",
    en: "Add Group",
  },
  CDMS_SUBJECT_GROUPING_ADD_SUCCESS: {
    ko: "그룹이 추가되었습니다.",
    en: "Group has been added.",
  },
  CDMS_SUBJECT_GROUPING_ADD_FAIL: {
    ko: "그룹 추가에 실패했습니다. ({error})",
    en: "Failed to add the group. ({error})",
  },
  CDMS_SUBJECT_GROUPING_ADD_MAXIMUM: {
    ko: "최대 {count}개까지 추가 가능합니다.",
    en: "You can only add up to {count}.",
  },
  CDMS_SUBJECT_GROUPING_EDIT_DIALOG_TITLE: {
    ko: "Edit Group",
    en: "Edit Group",
  },
  CDMS_SUBJECT_GROUPING_EDIT_SUCCESS: {
    ko: "그룹을 수정했습니다.",
    en: "Modified the group.",
  },
  CDMS_SUBJECT_GROUPING_EDIT_FAIL: {
    ko: "그룹 수정을 실패했습니다. ({error})",
    en: "Failed to modify the group. ({error})",
  },
  CDMS_SUBJECT_GROUPING_DELETE_DIALOG_TITLE: {
    ko: "Delete Group",
    en: "Delete Group",
  },
  CDMS_SUBJECT_GROUPING_DELETE_DIALOG_DESC: {
    ko: "해당 Group을 삭제하시겠습니까?",
    en: "Do you want to delete the group?",
  },
  CDMS_SUBJECT_GROUPING_DELETE_SUCCESS: {
    ko: "그룹이 삭제되었습니다.",
    en: "The group has been deleted.",
  },
  CDMS_SUBJECT_GROUPING_DELETE_FAIL: {
    ko: "그룹 삭제를 실패했습니다. ({error})",
    en: "Failed to delete the group. ({error})",
  },
  CDMS_SUBJECT_GROUPING_NO_DELETE: {
    ko: "해당 그룹은 이미 Subject에 지정되어 삭제할 수 없습니다.",
    en: "This group is already assigned to subject and cannot be deleted.",
  },
  CDMS_SUBJECT_GROUPING_COLUMN_ID_LABEL: {
    ko: "Column ID",
    en: "Column ID",
  },
  CDMS_SUBJECT_GROUPING_COLUMN_ID_PLACEHOLDER: {
    ko: "영문 대문자(A-Z), 숫자(0-9), 언더바(_)만 입력 가능합니다.",
    en: "Only enter uppercase letters (A-Z), numbers (0-9), and underscores (_).",
  },
  CDMS_SUBJECT_GROUPING_COLUMN_LANG_LABEL: {
    ko: "Column Labels",
    en: "Column Labels",
  },
  CDMS_SUBJECT_GROUPING_COLUMN_LANG_REQUIRED: {
    ko: "Label(EN) 항목을 필수로 입력해 주세요.",
    en: "The Label(EN) field is required.",
  },
  CDMS_SUBJECT_GROUPING_COLUMN_LANG_PLACEHOLDER: {
    ko: "Label({lang})",
    en: "Label({lang})",
  },
  CDMS_SUBJECT_GROUPING_VALUE: {
    ko: "Value",
    en: "Value",
  },
  CDMS_SUBJECT_GROUPING_VALUE_DB_PLACEHOLDER: {
    ko: "[Enter DB Val]",
    en: "[Enter DB Val]",
  },
  CDMS_SUBJECT_GROUPING_VALUE_UI_PLACEHOLDER: {
    ko: "[Enter UI Val]",
    en: "[Enter UI Val]",
  },
  CDMS_SUBJECT_GROUPING_VALUE_NO_DELETE: {
    ko: "해당 Value는 이미 Subject에 지정되어 삭제할 수 없습니다.",
    en: "This value is already assigned to subject and cannot be deleted.",
  },
  CDMS_SUBJECT_ASSIGN_GROUPING_TITLE: {
    ko: "Assign Group",
    en: "Assign Group",
  },
  CDMS_SUBJECT_ASSIGN_GROUPING_SUCCESS: {
    ko: "그룹 지정이 완료되었습니다.",
    en: "The grouping is completed.",
  },
  CDMS_SUBJECT_ASSIGN_GROUPING_FAIL: {
    ko: "그룹 지정을 실패했습니다. ({error})",
    en: "Grouping failed. ({error})",
  },
  CDMS_SUBJECT_ASSIGN_GROUPING_SITE_NAME_LABEL: {
    ko: "Site Name",
    en: "Site Name",
  },
  CDMS_SUBJECT_ASSIGN_GROUPING_SUBJECT_NO_LABEL: {
    ko: "Subject No.",
    en: "Subject No.",
  },
  CDMS_SUBJECT_ASSIGN_GROUPING_COLUMN_LABEL: {
    ko: "Column Label / Value",
    en: "Column Label / Value",
  },
  CDMS_SUBJECT_ASSIGN_GROUPING_NOT_SELECTED_VALUE: {
    ko: "선택하지 않음",
    en: "No Select",
  },
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_TITLE: {
    ko: "Subject Grouping Audit Trail",
    en: "Subject Grouping Audit Trail",
  },
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_CREATE: {
    ko: "Group 추가",
    en: "Added Group",
  },
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_DELETE: {
    ko: "Group 삭제",
    en: "Deleted Group",
  },
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_UPDATE_ID: {
    ko: "Column ID 변경",
    en: "Change Column ID",
  },
  CDMS_SUBJECT_GROUPING_AUDIT_TRAIL_UPDATE_LABEL: {
    ko: "Column Label 변경",
    en: "Change Column Label",
  },
};

MvnCodeInitiate(CDMS_STUDY_SUBJECT_GROUPING_LANG);
