import { CONVERTER_COMMON_LANG } from "./common";
import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_STUDY_DATA_FLOW_LANG: {
  DATA_FLOW_LIST_CURRENT: MvnCodeFE;
  DATA_FLOW_LIST_LIST: MvnCodeFE;
  DATA_FLOW_LIST_MENU_RENAME: MvnCodeFE;
  DATA_FLOW_LIST_MENU_DELETE: MvnCodeFE;
  DATA_FLOW_TOAST_RENAME_SUCCEED: MvnCodeFE;
  DATA_FLOW_TOAST_RENAME_FAIL: MvnCodeFE;
  DATA_FLOW_DIALOG_DELETE_DESCRIPTION: MvnCodeFE;
  DATA_FLOW_DIALOG_DRAFT_DELETE_DESCRIPTION: MvnCodeFE;
  DATA_FLOW_TOAST_DELETE_SUCCEED: MvnCodeFE;
  DATA_FLOW_TOAST_DELETE_FAIL: MvnCodeFE;
  BOARD_TITLE: MvnCodeFE;
  BOARD_EMPTY_TITLE: MvnCodeFE;
  BOARD_EMPTY_DESCRIPTION: MvnCodeFE;
  BOARD_BUTTON_NEW_FLOW: MvnCodeFE;
  BOARD_BUTTON_REFLOW: MvnCodeFE;
  BOARD_TOAST_REFLOW_DESCRIPTION: MvnCodeFE;
  BOARD_REFLOW_SUCCEED: MvnCodeFE;
  BOARD_REFLOW_FAIL: MvnCodeFE;
  BOARD_MENU_NEW_FLOW: MvnCodeFE;
  BOARD_MENU_IMPORT_DATASET: MvnCodeFE;
  BOARD_MENU_RESTORE: MvnCodeFE;
  BOARD_MENU_RESET: MvnCodeFE;
  BOARD_MENU_SAVE_AS: MvnCodeFE;
  BOARD_MENU_SAVE: MvnCodeFE;
  BOARD_DIALOG_NEW_FLOW_IMPORT_DATASET_TITLE: MvnCodeFE;
  BOARD_DIALOG_NEW_FLOW_IMPORT_DATASET_DESCRIPTION: MvnCodeFE;
  BOARD_DIALOG_IMPORT_DATASET_TITLE: MvnCodeFE;
  BOARD_DIALOG_IMPORT_DATASET_DESCRIPTION: MvnCodeFE;
  BOARD_DIALOG_RESET_TITLE: MvnCodeFE;
  BOARD_DIALOG_RESET_DESCRIPTION: MvnCodeFE;
  BOARD_TOAST_IMPORT_DATASET_SUCCEED: MvnCodeFE;
  BOARD_TOAST_IMPORT_DATASET_FAIL: MvnCodeFE;
  BOARD_TOAST_RESTORE_SUCCEED: MvnCodeFE;
  BOARD_TOAST_RESTORE_FAIL: MvnCodeFE;
  BOARD_TOAST_RESET_SUCCEED: MvnCodeFE;
  BOARD_TOAST_RESET_FAIL: MvnCodeFE;
  BOARD_TOAST_SAVE_SUCCEED: MvnCodeFE;
  BOARD_TOAST_SAVE_FAIL: MvnCodeFE;
  BOARD_DIALOG_SAVE_TITLE: MvnCodeFE;
  BOARD_DIALOG_SAVE_HELPER_TEXT: MvnCodeFE;
  BOARD_DIALOG_SAVE_AS_TITLE: MvnCodeFE;
  BOARD_TOAST_SAVE_AS_SUCCEED: MvnCodeFE;
  BOARD_TOAST_SAVE_AS_FAIL: MvnCodeFE;
  BOARD_DIALOG_IMPORT_DATASET_SELECTED: MvnCodeFE;
  BOARD_DIALOG_RESTORE_TITLE: MvnCodeFE;
  BOARD_DIALOG_RESTORE_DESCRIPTION: MvnCodeFE;
  NODE_MENU_PIVOT: MvnCodeFE;
  NODE_MENU_UNION: MvnCodeFE;
  NODE_MENU_JOIN: MvnCodeFE;
  NODE_MENU_CLONE: MvnCodeFE;
  NODE_MENU_SDTM_MAPPING: MvnCodeFE;
  NODE_MENU_SAVE_AS: MvnCodeFE;
  NODE_MENU_SAVE: MvnCodeFE;
  NODE_MENU_RENAME: MvnCodeFE;
  NODE_MENU_DELETE: MvnCodeFE;
  NODE_DIALOG_PIVOT_TITLE: MvnCodeFE;
  NODE_DIALOG_PIVOT_DESCRIPTION: MvnCodeFE;
  NODE_TOAST_PIVOT_SUCCEED: MvnCodeFE;
  NODE_TOAST_PIVOT_FAIL: MvnCodeFE;
  NODE_DIALOG_UNION_TITLE: MvnCodeFE;
  NODE_DIALOG_UNION_DESCRIPTION: MvnCodeFE;
  NODE_DIALOG_UNION_HELPER_TEXT: MvnCodeFE;
  NODE_DIALOG_UNION_EMPTY: MvnCodeFE;
  NODE_TOAST_UNION_SUCCEED: MvnCodeFE;
  NODE_TOAST_UNION_FAIL: MvnCodeFE;
  NODE_DIALOG_JOIN_TITLE: MvnCodeFE;
  NODE_DIALOG_JOIN_DESCRIPTION: MvnCodeFE;
  NODE_TOAST_JOIN_SUCCEED: MvnCodeFE;
  NODE_TOAST_JOIN_FAIL: MvnCodeFE;
  NODE_TOAST_CLONE_SUCCEED: MvnCodeFE;
  NODE_TOAST_CLONE_FAIL: MvnCodeFE;
  NODE_TOAST_EXPORT_SUCCEED: MvnCodeFE;
  NODE_TOAST_EXPORT_FAIL: MvnCodeFE;
  NODE_TOAST_SAVE_AS_SUCCEED: MvnCodeFE;
  NODE_TOAST_SAVE_AS_FAIL: MvnCodeFE;
  NODE_DIALOG_DELETE_TITLE: MvnCodeFE;
  NODE_DIALOG_DELETE_DESCRIPTION: MvnCodeFE;
  NODE_TOAST_DELETE_SUCCEED: MvnCodeFE;
  NODE_TOAST_DELETE_FAIL: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_TITLE: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_EMPTY_TITLE: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_EMPTY_DESCRIPTION: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_SELECT_PLACEHOLDER: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_CONFIRM: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_CANCEL: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_PROGRESS: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_ONE_TO_ONE_SUCCEED: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_ONE_TO_ONE_FAIL: MvnCodeFE;
  NODE_DIALOG_SDTM_MAPPING_CT_TITLE: MvnCodeFE;
  NODE_DIALOG_SDTM_MAPPING_CT_DESCRIPTION: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_CT_SUCCEED: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_CT_FAIL: MvnCodeFE;
  NODE_DIALOG_SDTM_MAPPING_FACTOR_TITLE: MvnCodeFE;
  NODE_DIALOG_SDTM_MAPPING_FACTOR_DESCRIPTION: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_FACTOR_SUCCEED: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_FACTOR_FAIL: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_NULL_SUCCEED: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_NULL_FAIL: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_SUCCEED: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_FAIL: MvnCodeFE;
  NODE_DRAWER_SDTM_MAPPING_AUTO: MvnCodeFE;
  NODE_DIALOG_FLOW_SAVE_ALERT_TITLE: MvnCodeFE;
  NODE_DIALOG_FLOW_SAVE_ALERT_DERIVED_DESCRIPTION: MvnCodeFE;
  NODE_DIALOG_FLOW_SAVE_ALERT_SDTM_DESCRIPTION: MvnCodeFE;
  NODE_EXPORT_SAVE_REQUIRED_ALERT: MvnCodeFE;
  NODE_EXPORT_SAVE_REQUIRED_TOOLTIP: MvnCodeFE;
  NODE_TOAST_EXPORT_DELETE_FAIL: MvnCodeFE;
  DATA_TABLE_TITLE: MvnCodeFE;
  DATA_TABLE_EMPTY: MvnCodeFE;
  DATA_TABLE_MENU_ORDER_BY: MvnCodeFE;
  DATA_TABLE_MENU_FILTER: MvnCodeFE;
  DATA_TABLE_MENU_HISTORY: MvnCodeFE;
  DATA_TABLE_MENU_REMOVE_DUPLICATES: MvnCodeFE;
  DATA_TABLE_DIALOG_ORDER_BY_TITLE: MvnCodeFE;
  DATA_TABLE_DIALOG_ORDER_BY_PLACEHOLDER: MvnCodeFE;
  DATA_TABLE_DIALOG_MENU_ORDER_BY_ADD: MvnCodeFE;
  DATA_TABLE_DIALOG_MENU_ORDER_BY_DELETE: MvnCodeFE;
  DATA_TABLE_DIALOG_TOOLTIP_ORDER_BY_ASC: MvnCodeFE;
  DATA_TABLE_DIALOG_TOOLTIP_ORDER_BY_DESC: MvnCodeFE;
  DATA_TABLE_TOAST_ORDER_BY_SUCCEED: MvnCodeFE;
  DATA_TABLE_TOAST_ORDER_BY_FAIL: MvnCodeFE;
  DATA_TABLE_DIALOG_FILTER_TITLE: MvnCodeFE;
  DATA_TABLE_DIALOG_FILTER_VARIABLE_PLACEHOLDER: MvnCodeFE;
  DATA_TABLE_DIALOG_FILTER_INPUT_PLACEHOLDER: MvnCodeFE;
  DATA_TABLE_DIALOG_FILTER_MENU_ADD: MvnCodeFE;
  DATA_TABLE_DIALOG_FILTER_MENU_DELETE: MvnCodeFE;
  DATA_TABLE_TOAST_FILTER_SUCCEED: MvnCodeFE;
  DATA_TABLE_TOAST_FILTER_FAIL: MvnCodeFE;
  DATA_TABLE_DIALOG_HISTORY_TITLE: MvnCodeFE;
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_ASC: MvnCodeFE;
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_DESC: MvnCodeFE;
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_UNDO: MvnCodeFE;
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_REDO: MvnCodeFE;
  DATA_TABLE_DIALOG_HISTORY_BADGE_FAIL: MvnCodeFE;
  DATA_TABLE_TOAST_HISTORY_TOOLTIP_DELETE: MvnCodeFE;
  DATA_TABLE_TOAST_HISTORY_DELETE_SUCCEED: MvnCodeFE;
  DATA_TABLE_TOAST_HISTORY_DELETE_FAIL: MvnCodeFE;
  DATA_TABLE_TOAST_HISTORY_SUCCEED: MvnCodeFE;
  DATA_TABLE_TOAST_HISTORY_FAIL: MvnCodeFE;
  DATA_TABLE_FILED_MENU_FILTER: MvnCodeFE;
  DATA_TABLE_FILED_MENU_CDISC: MvnCodeFE;
  DATA_TABLE_FILED_MENU_AGGREGATE: MvnCodeFE;
  DATA_TABLE_FILED_MENU_REMOVE_DUPLICATES: MvnCodeFE;
  DATA_TABLE_FILED_MENU_DELETE: MvnCodeFE;
  DATA_TABLE_FILED_SORT: MvnCodeFE;
  DATA_TABLE_FILED_ASC: MvnCodeFE;
  DATA_TABLE_FILED_DESC: MvnCodeFE;
  DATA_TABLE_POPOVER_AGGREGATE: MvnCodeFE;
  DATA_TABLE_POPOVER_AGGREGATE_EMPTY: MvnCodeFE;
  DATA_TABLE_POPOVER_TOOLTIP_AGGREGATE: MvnCodeFE;
  DATA_TABLE_DIALOG_REMOVE_DUPLICATES_TITLE: MvnCodeFE;
  DATA_TABLE_DIALOG_REMOVE_DUPLICATES_DESCRIPTION: MvnCodeFE;
  DATA_TABLE_DIALOG_REMOVE_DUPLICATES_PLACEHOLDER: MvnCodeFE;
  DATA_TABLE_TOAST_REMOVE_DUPLICATES_SUCCEED: MvnCodeFE;
  DATA_TABLE_TOAST_REMOVE_DUPLICATES_FAIL: MvnCodeFE;
  DATA_TABLE_TOAST_VARIABLE_DELETE_SUCCEED: MvnCodeFE;
  DATA_TABLE_TOAST_VARIABLE_DELETE_FAIL: MvnCodeFE;
  DATA_TABLE_TOAST_VARIABLE_RENAME_FAIL: MvnCodeFE;
  DATA_TABLE_FIELD_TYPE_DATE: MvnCodeFE;
  DATA_TABLE_FIELD_TYPE_DATETIME: MvnCodeFE;
  DATA_TABLE_FIELD_TYPE_TEXT: MvnCodeFE;
  DATA_TABLE_FIELD_TYPE_INTEGER: MvnCodeFE;
  DATA_TABLE_FIELD_TYPE_FLOAT: MvnCodeFE;
  DATA_TABLE_TOAST_TYPE_CASTING_SUCCESS: MvnCodeFE;
  DATA_TABLE_TOAST_TYPE_CASTING_FAIL: MvnCodeFE;
  DATA_TABLE_TOAST_UNSUPPORTED_TYPE_CASTING_FAIL: MvnCodeFE;
  POPOVER_CDISC_INFORMATION: MvnCodeFE;

  TOOLTIP_MORE: MvnCodeFE;
  DIALOG_SAVE: MvnCodeFE;
  DIALOG_CONFIRM: MvnCodeFE;
  DIALOG_CANCEL: MvnCodeFE;
} = {
  DATA_FLOW_LIST_CURRENT: {
    ko: "현재 흐름",
    en: "Current Flow",
  },
  DATA_FLOW_LIST_LIST: {
    ko: "흐름 목록",
    en: "Flow List",
  },
  DATA_FLOW_LIST_MENU_RENAME: {
    ko: "이름 변경",
    en: "Rename",
  },
  DATA_FLOW_LIST_MENU_DELETE: {
    ko: "삭제",
    en: "Delete",
  },
  DATA_FLOW_TOAST_RENAME_SUCCEED: {
    ko: "이름 변경을 완료했습니다.",
    en: "The name has been changed.",
  },
  DATA_FLOW_TOAST_RENAME_FAIL: {
    ko: "이름 변경에 실패했습니다.\n동일한 이름이 있습니다.",
    en: "Failed to change the name.\nA duplicate name exists.",
  },
  DATA_FLOW_DIALOG_DELETE_DESCRIPTION: {
    ko: "선택한 흐름을 삭제하시겠습니까?",
    en: "Do you want to delete the selected flow?",
  },
  DATA_FLOW_DIALOG_DRAFT_DELETE_DESCRIPTION: {
    ko: "저장되지 않은 흐름입니다.\n선택한 흐름을 삭제하시겠습니까?",
    en: "This flow has not been saved.\nDo you want to delete the selected flow?",
  },
  DATA_FLOW_TOAST_DELETE_SUCCEED: {
    ko: "흐름을 삭제 했습니다.",
    en: "The flow has been deleted.",
  },
  DATA_FLOW_TOAST_DELETE_FAIL: {
    ko: "흐름 삭제에 실패했습니다.",
    en: "Failed to delete the flow.",
  },
  BOARD_TITLE: {
    ko: "작업보드",
    en: "Board",
  },
  BOARD_EMPTY_TITLE: {
    ko: "등록된 데이터가 없습니다.",
    en: "No data has been registered.",
  },
  BOARD_EMPTY_DESCRIPTION: {
    ko: "버튼을 눌러 새 플로우를 생성하세요.",
    en: "Click the button to create a new flow.",
  },
  BOARD_BUTTON_NEW_FLOW: {
    ko: "새 흐름",
    en: "New Flow",
  },
  BOARD_BUTTON_REFLOW: {
    ko: "재흐름",
    en: "Reflow",
  },
  BOARD_TOAST_REFLOW_DESCRIPTION: {
    ko: "재수행이 필요합니다. [재흐름] 버튼을 눌러 주세요.",
    en: "Reflow is necessary. Please click the Reflow button.",
  },
  BOARD_REFLOW_SUCCEED: {
    ko: "흐름 재수행을 완료했습니다.",
    en: "The flow has been rerun.",
  },
  BOARD_REFLOW_FAIL: {
    ko: "흐름 재수행에 실패했습니다.",
    en: "Failed to rerun the flow.",
  },
  BOARD_MENU_NEW_FLOW: {
    ko: "새 흐름",
    en: "New Flow",
  },
  BOARD_MENU_IMPORT_DATASET: {
    ko: "데이터 세트 가져오기",
    en: "Import Dataset",
  },
  BOARD_MENU_RESTORE: {
    ko: "흐름 복원",
    en: "Restore",
  },
  BOARD_MENU_RESET: {
    ko: "흐름 초기화",
    en: "Reset",
  },
  BOARD_MENU_SAVE_AS: {
    ko: "다른 이름으로 저장",
    en: "Save As",
  },
  BOARD_MENU_SAVE: {
    ko: "저장",
    en: "Save",
  },
  BOARD_DIALOG_NEW_FLOW_IMPORT_DATASET_TITLE: {
    ko: "새 흐름에 데이터 세트 추가하기",
    en: "Import Dataset in New Flow",
  },
  BOARD_DIALOG_NEW_FLOW_IMPORT_DATASET_DESCRIPTION: {
    ko: "사용할 데이터 세트를 선택해 주세요.",
    en: "Select the dataset to be used.",
  },
  BOARD_DIALOG_IMPORT_DATASET_TITLE: {
    ko: "데이터 세트 추가하기",
    en: "Import Dataset",
  },
  BOARD_DIALOG_IMPORT_DATASET_DESCRIPTION: {
    ko: "사용할 데이터 세트를 선택해 주세요.",
    en: "Select the dataset to be used.",
  },
  BOARD_TOAST_IMPORT_DATASET_SUCCEED: {
    ko: "데이터 세트를 추가했습니다.",
    en: "The dataset has been added to the board.",
  },
  BOARD_TOAST_IMPORT_DATASET_FAIL: {
    ko: "데이터 세트 추가에 실패했습니다.",
    en: "Failed to add the dataset to the board.",
  },
  BOARD_TOAST_RESTORE_SUCCEED: {
    ko: "흐름 복원을 완료했습니다.",
    en: "The flow restore has been completed.",
  },
  BOARD_TOAST_RESTORE_FAIL: {
    ko: "흐름 복원에 실패했습니다.",
    en: "Failed to restore the flow.",
  },
  BOARD_DIALOG_RESET_TITLE: {
    ko: "초기화",
    en: "Reset",
  },
  BOARD_DIALOG_RESET_DESCRIPTION: {
    ko: "현재 작업 중인 흐름을 초기화 하시겠습니까?",
    en: "Are you sure you want to reset the file you are currently working on?",
  },
  BOARD_TOAST_RESET_SUCCEED: {
    ko: "흐름 초기화를 완료했습니다.",
    en: "The flow reset has been completed.",
  },
  BOARD_TOAST_RESET_FAIL: {
    ko: "흐름 초기화에 실패했습니다.",
    en: "Failed to reset the flow.",
  },
  BOARD_DIALOG_SAVE_TITLE: CONVERTER_COMMON_LANG.SAVE_VERB,
  BOARD_DIALOG_SAVE_HELPER_TEXT: {
    ko: "중복된 파일 이름이 존재합니다. 다른 이름으로 저장해 주십시오.",
    en: "A duplicate file name exists. Please save it with a different name.A duplicate file exists.",
  },
  BOARD_TOAST_SAVE_SUCCEED: {
    ko: "흐름을 저장했습니다.",
    en: "The flow has been saved.",
  },
  BOARD_TOAST_SAVE_FAIL: {
    ko: "흐름 저장에 실패했습니다.",
    en: "Failed to save the flow.",
  },
  BOARD_DIALOG_SAVE_AS_TITLE: {
    ko: "다른 이름으로 저장",
    en: "Save as",
  },
  BOARD_TOAST_SAVE_AS_SUCCEED: {
    ko: "다른 이름으로 저장했습니다.",
    en: "The Save As operation has been completed.",
  },
  BOARD_TOAST_SAVE_AS_FAIL: {
    ko: "다른 이름으로 저장에 실패했습니다.",
    en: "Failed to perform Save As.",
  },
  BOARD_DIALOG_IMPORT_DATASET_SELECTED: {
    ko: "개 선택됨",
    en: "items selected.",
  },
  BOARD_DIALOG_RESTORE_TITLE: {
    ko: "서버 저장 시점으로 복원",
    en: "Restore",
  },
  BOARD_DIALOG_RESTORE_DESCRIPTION: {
    ko: "흐름의 작업상태가 이전 서버 저장 시점으로 복원됩니다.\n복원하시겠습니까?",
    en: "The Flow's task status will be restored to the previous server save point.\nWould you like to proceed with the restoration?",
  },
  NODE_MENU_PIVOT: {
    ko: "피벗",
    en: "Pivot",
  },
  NODE_MENU_UNION: {
    ko: "유니온",
    en: "Union",
  },
  NODE_MENU_JOIN: {
    ko: "조인",
    en: "Join",
  },
  NODE_MENU_CLONE: {
    ko: "값으로 데이터 세트 복제",
    en: "Clone Dataset by Value",
  },
  NODE_MENU_SDTM_MAPPING: {
    ko: "SDTM 매핑",
    en: "SDTM Mapping",
  },
  NODE_MENU_SAVE_AS: {
    ko: "다른 이름으로 저장",
    en: "Save as",
  },
  NODE_MENU_SAVE: {
    ko: "저장",
    en: "Save",
  },
  NODE_MENU_RENAME: {
    ko: "이름 변경",
    en: "Rename",
  },
  NODE_MENU_DELETE: {
    ko: "삭제",
    en: "Delete",
  },
  NODE_DIALOG_PIVOT_TITLE: {
    ko: "피벗",
    en: "Pivot",
  },
  NODE_DIALOG_PIVOT_DESCRIPTION: {
    ko: "피벗 종류를 선택해 주세요.",
    en: "Select a pivot type.",
  },
  NODE_TOAST_PIVOT_SUCCEED: {
    ko: "피벗 작업을 완료했습니다.",
    en: "The pivot operation has been completed.",
  },
  NODE_TOAST_PIVOT_FAIL: {
    ko: "피벗 작업에 실패했습니다.",
    en: "Failed to perform the pivot operation.",
  },
  NODE_DIALOG_UNION_TITLE: {
    ko: "유니온",
    en: "Union",
  },
  NODE_DIALOG_UNION_DESCRIPTION: {
    ko: "유니온 종류를 선택해 주세요.",
    en: "Select a union type.",
  },
  NODE_DIALOG_UNION_HELPER_TEXT: {
    ko: "각 데이터 세트의 2개 행을 대상으로 결합을 수행하여 미리 보기 합니다.",
    en: "The union will be performed on two rows of each dataset, and a preview will be displayed.",
  },
  NODE_DIALOG_UNION_EMPTY: {
    ko: "데이터가 없습니다.",
    en: "There is no data.",
  },
  NODE_TOAST_UNION_SUCCEED: {
    ko: "유니온 작업을 완료했습니다.",
    en: "The union operation has been completed.",
  },
  NODE_TOAST_UNION_FAIL: {
    ko: "유니온 작업에 실패했습니다.",
    en: "Failed to perform the union operation.",
  },
  NODE_DIALOG_JOIN_TITLE: {
    ko: "조인",
    en: "Join",
  },
  NODE_DIALOG_JOIN_DESCRIPTION: {
    ko: "조인 종류를 선택해 주세요.",
    en: "Select the desired join type.",
  },
  NODE_TOAST_JOIN_SUCCEED: {
    ko: "조인 작업을 완료했습니다.",
    en: "The join operation has been completed.",
  },
  NODE_TOAST_JOIN_FAIL: {
    ko: "조인 작업에 실패했습니다.",
    en: "Failed to perform the join operation.",
  },
  NODE_TOAST_CLONE_SUCCEED: {
    ko: "값으로 데이터 세트 복제를 완료했습니다.",
    en: "The Clone Dataset by Value operation has been completed.",
  },
  NODE_TOAST_CLONE_FAIL: {
    ko: "값으로 데이터 세트 복제에 실패했습니다.",
    en: "Failed to clone the dataset by value.",
  },
  NODE_TOAST_EXPORT_SUCCEED: {
    ko: "내보내기를 완료했습니다.",
    en: "Export has been completed.",
  },
  NODE_TOAST_EXPORT_FAIL: {
    ko: "내보내기에 실패했습니다.",
    en: "Failed to export.",
  },
  NODE_DRAWER_SDTM_MAPPING_TITLE: {
    ko: "SDTM 매핑",
    en: "SDTM Final Mapping",
  },
  NODE_DRAWER_SDTM_MAPPING_EMPTY_TITLE: {
    ko: "데이터가 없습니다.",
    en: "There is no SDTM Final Mapping data.",
  },
  NODE_DRAWER_SDTM_MAPPING_EMPTY_DESCRIPTION: {
    ko: "Target Dataset을 눌러\n데이터 세트를 불러 오세요.",
    en: "There is no SDTM Final Mapping data.\nClick Target Dataset to load the dataset.",
  },
  NODE_DRAWER_SDTM_MAPPING_SELECT_PLACEHOLDER: {
    ko: "선택...",
    en: "Select...",
  },
  NODE_DRAWER_SDTM_MAPPING_CONFIRM: CONVERTER_COMMON_LANG.CONFIRM_VERB,
  NODE_DRAWER_SDTM_MAPPING_CANCEL: CONVERTER_COMMON_LANG.CANCEL_VERB,
  NODE_DRAWER_SDTM_MAPPING_PROGRESS: {
    ko: "진행률",
    en: "Progress",
  },
  NODE_DRAWER_SDTM_MAPPING_ONE_TO_ONE_SUCCEED: {
    ko: "1:1 할당을 완료했습니다.",
    en: "1:1 assignment has been completed.",
  },
  NODE_DRAWER_SDTM_MAPPING_ONE_TO_ONE_FAIL: {
    ko: "1:1 할당 수행에 실패했습니다.",
    en: "Failed to assign 1:1.",
  },
  NODE_DIALOG_SDTM_MAPPING_CT_TITLE: {
    ko: "지정 용어 적용",
    en: "Assign CT",
  },
  NODE_DIALOG_SDTM_MAPPING_CT_DESCRIPTION: {
    ko: "각 값에 해당하는 용어를 할당해 주세요.",
    en: "Select a term for each value.",
  },
  NODE_DRAWER_SDTM_MAPPING_CT_SUCCEED: {
    ko: "제어된 용어 적용을 수행했습니다.",
    en: "Controlled Terminology assignment has been completed.",
  },
  NODE_DRAWER_SDTM_MAPPING_CT_FAIL: {
    ko: "제어된 용어 적용 수행에 실패했습니다.",
    en: "Failed to assign Controlled Terminology.",
  },
  NODE_DIALOG_SDTM_MAPPING_FACTOR_TITLE: {
    ko: "변환계수 적용",
    en: "Conversion Factors",
  },
  NODE_DIALOG_SDTM_MAPPING_FACTOR_DESCRIPTION: {
    ko: "적용할 변환계수를 선택해 주세요.",
    en: "Select the conversion factor to apply.",
  },
  NODE_DRAWER_SDTM_MAPPING_FACTOR_SUCCEED: {
    ko: "변환계수 적용을 완료했습니다.",
    en: "Conversion Factors applied successfully.",
  },
  NODE_DRAWER_SDTM_MAPPING_FACTOR_FAIL: {
    ko: "변환계수 적용에 실패했습니다.",
    en: "Failed to apply Conversion Factors.",
  },
  NODE_DRAWER_SDTM_MAPPING_NULL_SUCCEED: {
    ko: "null값 할당을 완료했습니다.",
    en: "Null assign completed successfully.",
  },
  NODE_DRAWER_SDTM_MAPPING_NULL_FAIL: {
    ko: "null값 할당에 실패했습니다.",
    en: "Failed to assign Null values.",
  },
  NODE_DRAWER_SDTM_MAPPING_SUCCEED: {
    ko: "SDTM 매핑을 완료했습니다.",
    en: "The SDTM Mapping operation has been completed.",
  },
  NODE_DRAWER_SDTM_MAPPING_FAIL: {
    ko: "SDTM 매핑에 실패했습니다.",
    en: "Failed to perform SDTM Mapping.",
  },
  NODE_DRAWER_SDTM_MAPPING_AUTO: {
    ko: "Auto Mapping",
    en: "Auto Mapping",
  },
  NODE_TOAST_SAVE_AS_SUCCEED: {
    ko: "데이터 세트를 저장했습니다.",
    en: "The derived dataset has been saved.",
  },
  NODE_DIALOG_DELETE_TITLE: {
    ko: "노드 삭제",
    en: "Delete Node",
  },
  NODE_DIALOG_DELETE_DESCRIPTION: {
    ko: "노드 삭제시 하위 노드들과의 관계가 끊어집니다.\n삭제하시겠습니까?",
    en: "When a node is deleted, its relationships with subordinate nodes will be disconnected.\nto proceed with the deletion?",
  },
  NODE_TOAST_SAVE_AS_FAIL: {
    ko: "데이터 세트 저장에 실패했습니다.",
    en: "Failed to save the derived dataset.",
  },
  NODE_TOAST_EXPORT_DELETE_FAIL: {
    ko: "해당 파일은 다른 흐름에서 참조하고 있으므로 삭제가 불가능합니다.",
    en: "This file cannot be deleted as it is referenced in another flow.",
  },
  NODE_TOAST_DELETE_SUCCEED: {
    ko: "데이터 세트를 삭제했습니다.",
    en: "The dataset has been deleted.",
  },
  NODE_TOAST_DELETE_FAIL: {
    ko: "데이터 세트 삭제에 실패했습니다.",
    en: "Failed to delete the dataset.",
  },
  NODE_DIALOG_FLOW_SAVE_ALERT_TITLE: {
    ko: "흐름 저장 요청",
    en: "Save Flow Request",
  },
  NODE_DIALOG_FLOW_SAVE_ALERT_DERIVED_DESCRIPTION: {
    ko: "파일 저장을 위해 현재 흐름을 저장해 주십시오.",
    en: "Please save the current flow to store the file.",
  },
  NODE_DIALOG_FLOW_SAVE_ALERT_SDTM_DESCRIPTION: {
    ko: "SDTM Mapping을 수행하기 위해 현재 흐름을 저장해 주십시오.",
    en: "Please save the current flow to perform SDTM mapping.",
  },
  NODE_EXPORT_SAVE_REQUIRED_ALERT: {
    ko: "재저장 필요",
    en: "Save Required",
  },
  NODE_EXPORT_SAVE_REQUIRED_TOOLTIP: {
    ko: "저장",
    en: "Save",
  },
  DATA_TABLE_TITLE: {
    ko: "데이터 테이블",
    en: "Data Table",
  },
  DATA_TABLE_EMPTY: {
    ko: "데이터가 없습니다.",
    en: "There is no data.",
  },
  DATA_TABLE_MENU_ORDER_BY: {
    ko: "정렬",
    en: "Order by",
  },
  DATA_TABLE_MENU_FILTER: {
    ko: "필터",
    en: "Filter",
  },
  DATA_TABLE_MENU_HISTORY: {
    ko: "수행 이력",
    en: "Execution history",
  },
  DATA_TABLE_MENU_REMOVE_DUPLICATES: {
    ko: "중복 제거",
    en: "Remove duplicates",
  },
  DATA_TABLE_DIALOG_ORDER_BY_TITLE: {
    ko: "정렬",
    en: "Order by",
  },
  DATA_TABLE_DIALOG_ORDER_BY_PLACEHOLDER: {
    ko: "변수",
    en: "Variable",
  },
  DATA_TABLE_DIALOG_MENU_ORDER_BY_ADD: {
    ko: "추가",
    en: "Add",
  },
  DATA_TABLE_DIALOG_MENU_ORDER_BY_DELETE: {
    ko: "삭제",
    en: "Delete",
  },
  DATA_TABLE_DIALOG_TOOLTIP_ORDER_BY_ASC: {
    ko: "오름차순",
    en: "Ascending Sort",
  },
  DATA_TABLE_DIALOG_TOOLTIP_ORDER_BY_DESC: {
    ko: "내림차순",
    en: "Descending Sort",
  },
  DATA_TABLE_TOAST_ORDER_BY_SUCCEED: {
    ko: "정렬 적용을 완료했습니다.",
    en: "Order by has been applied.",
  },
  DATA_TABLE_TOAST_ORDER_BY_FAIL: {
    ko: "정렬 적용에 실패했습니다.",
    en: "Failed to apply order by.",
  },
  DATA_TABLE_DIALOG_FILTER_TITLE: {
    ko: "필터",
    en: "Filter",
  },
  DATA_TABLE_DIALOG_FILTER_VARIABLE_PLACEHOLDER: {
    ko: "변수",
    en: "Variable",
  },
  DATA_TABLE_DIALOG_FILTER_INPUT_PLACEHOLDER: {
    ko: "입력",
    en: "Enter search term",
  },
  DATA_TABLE_DIALOG_FILTER_MENU_ADD: {
    ko: "필터 추가",
    en: "Add Filter",
  },
  DATA_TABLE_DIALOG_FILTER_MENU_DELETE: CONVERTER_COMMON_LANG.DELETE_VERB,
  DATA_TABLE_TOAST_FILTER_SUCCEED: {
    ko: "필터 적용을 완료했습니다.",
    en: "Filter has been completed.",
  },
  DATA_TABLE_TOAST_FILTER_FAIL: {
    ko: "필터 적용에 실패했습니다.",
    en: "Failed to apply filter.",
  },
  DATA_TABLE_DIALOG_HISTORY_TITLE: {
    ko: "수행 이력",
    en: "Execution History",
  },
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_ASC: {
    ko: "오름차순",
    en: "Ascending",
  },
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_DESC: {
    ko: "내림차순",
    en: "Descending Sort",
  },
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_UNDO: {
    ko: "실행 취소",
    en: "Undo",
  },
  DATA_TABLE_DIALOG_HISTORY_TOOLTIP_REDO: {
    ko: "다시 실행",
    en: "Redo",
  },
  DATA_TABLE_DIALOG_HISTORY_BADGE_FAIL: {
    ko: "실패",
    en: "Fail",
  },
  DATA_TABLE_TOAST_HISTORY_TOOLTIP_DELETE: CONVERTER_COMMON_LANG.DELETE_VERB,
  DATA_TABLE_TOAST_HISTORY_DELETE_SUCCEED: {
    ko: "요청한 수행 이력을 삭제했습니다.",
    en: "Requested performance history deletion completed.",
  },
  DATA_TABLE_TOAST_HISTORY_DELETE_FAIL: {
    ko: "요청한 수행 이력 삭제에 실패했습니다.",
    en: "Failed to delete the requested performance history.",
  },
  DATA_TABLE_TOAST_HISTORY_SUCCEED: {
    ko: "요청한 수행 이력을 삭제했습니다.",
    en: "Requested performance history deletion completed.",
  },
  DATA_TABLE_TOAST_HISTORY_FAIL: {
    ko: "요청한 수행 이력 삭제에 실패했습니다.",
    en: "Failed to delete the requested performance history.",
  },
  DATA_TABLE_TOAST_REMOVE_DUPLICATES_SUCCEED: {
    ko: "중복값을 삭제했습니다.",
    en: "Duplicate value deletion has been completed.",
  },
  DATA_TABLE_TOAST_REMOVE_DUPLICATES_FAIL: {
    ko: "중복값 삭제에 실패했습니다.",
    en: "Failed to delete duplicate values.",
  },
  DATA_TABLE_FILED_MENU_FILTER: {
    ko: "필터",
    en: "Filter",
  },
  DATA_TABLE_FILED_MENU_CDISC: {
    ko: "CDISC 변수 정보",
    en: "CDISC Variable Information",
  },
  DATA_TABLE_FILED_MENU_AGGREGATE: {
    ko: "집계",
    en: "Aggregate",
  },
  DATA_TABLE_FILED_MENU_REMOVE_DUPLICATES: {
    ko: "중복 제거",
    en: "Remove duplicates",
  },
  DATA_TABLE_FILED_MENU_DELETE: CONVERTER_COMMON_LANG.DELETE_VERB,
  DATA_TABLE_FILED_SORT: {
    ko: "정렬",
    en: "Sort",
  },
  DATA_TABLE_FILED_ASC: {
    ko: "오름차순",
    en: "Ascending",
  },
  DATA_TABLE_FILED_DESC: {
    ko: "내림차순",
    en: "Descending",
  },
  DATA_TABLE_POPOVER_AGGREGATE: {
    ko: "집계",
    en: "Agg.",
  },
  DATA_TABLE_POPOVER_AGGREGATE_EMPTY: {
    ko: "집계 내역이 없습니다.",
    en: "There is no aggregation result.",
  },
  DATA_TABLE_POPOVER_TOOLTIP_AGGREGATE: {
    ko: "집계",
    en: "Aggregated Distinct Values",
  },
  DATA_TABLE_DIALOG_REMOVE_DUPLICATES_TITLE: {
    ko: "중복제거",
    en: "Remove duplicates",
  },
  DATA_TABLE_DIALOG_REMOVE_DUPLICATES_DESCRIPTION: {
    ko: "중복제거 대상 변수를 선택해 주세요.",
    en: "Select the variable to be deduplicated.",
  },
  DATA_TABLE_DIALOG_REMOVE_DUPLICATES_PLACEHOLDER: {
    ko: "중복 대상 변수 선택",
    en: "Select the variable for duplicate removal. ",
  },
  DATA_TABLE_TOAST_VARIABLE_DELETE_SUCCEED: {
    ko: "{variable} 변수를 삭제했습니다.",
    en: "{variable} variable has been successfully deleted.",
  },
  DATA_TABLE_TOAST_VARIABLE_DELETE_FAIL: {
    ko: "{variable} 변수 삭제에 실패했습니다.",
    en: "Failed to delete the {variable} variable.",
  },
  DATA_TABLE_TOAST_VARIABLE_RENAME_FAIL: {
    ko: "이미 존재하는 변수명입니다.",
    en: "The variable name already exists.",
  },
  DATA_TABLE_FIELD_TYPE_DATE: {
    ko: "날짜형",
    en: "Date",
  },
  DATA_TABLE_FIELD_TYPE_DATETIME: {
    ko: "날짜시간형",
    en: "Datetime",
  },
  DATA_TABLE_FIELD_TYPE_TEXT: {
    ko: "문자형",
    en: "Text",
  },
  DATA_TABLE_FIELD_TYPE_INTEGER: {
    ko: "정수형",
    en: "Integer",
  },
  DATA_TABLE_FIELD_TYPE_FLOAT: {
    ko: "실수형",
    en: "Float",
  },
  DATA_TABLE_TOAST_TYPE_CASTING_SUCCESS: {
    ko: "{variable}의 데이터 타입을 {type}으로(로) 변경했습니다.",
    en: "The data type of {variable} has been changed to {type}.",
  },
  DATA_TABLE_TOAST_TYPE_CASTING_FAIL: {
    ko: "{variable}의 데이터 타입 변경에 실패했습니다.",
    en: "Failed to change the data type of {variable}.",
  },
  DATA_TABLE_TOAST_UNSUPPORTED_TYPE_CASTING_FAIL: {
    ko: "변경할 수 없는 데이터 타입입니다.",
    en: "This data type cannot be changed.",
  },
  POPOVER_CDISC_INFORMATION: {
    ko: "CDISC 변수 정보",
    en: "CDISC Variable Information",
  },
  TOOLTIP_MORE: {
    ko: "더보기",
    en: "More Option",
  },

  DIALOG_SAVE: CONVERTER_COMMON_LANG.SAVE_VERB,
  DIALOG_CONFIRM: CONVERTER_COMMON_LANG.CONFIRM_VERB,
  DIALOG_CANCEL: CONVERTER_COMMON_LANG.CANCEL_VERB,
};

MvnCodeInitiate(CONVERTER_STUDY_DATA_FLOW_LANG);
