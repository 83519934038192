import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const DOCS_FOLDER_LANG: {
  DOCS_FOLDER_COMMON_CANCEL_NOUN: MvnCodeFE;
  DOCS_FOLDER_COMMON_SAVE_NOUN: MvnCodeFE;
  DOCS_FOLDER_COMMON_DELETE_NOUN: MvnCodeFE;
  DOCS_FOLDER_COMMON_ADD_NOUN: MvnCodeFE;
  DOCS_FOLDER_COMMON_CREATE_NOUN: MvnCodeFE;
  DOCS_FOLDER_COMMON_OK_NOUN: MvnCodeFE;
  DOCS_FOLDER_COMMON_CLOSE_NOUN: MvnCodeFE;
  DOCS_FOLDER_ADD_DIALOG_HEADER: MvnCodeFE;
  DOCS_FOLDER_NAME_LABEL: MvnCodeFE;
  DOCS_FOLDER_ARCHIVER_NAME_PLACEHOLDER: MvnCodeFE;
  DOCS_FOLDER_ARCHIVER_NAME_LABEL: MvnCodeFE;
  DOCS_FOLDER_NAME_PLACEHOLDER: MvnCodeFE;
  DOCS_FOLDER_CREATE_SUCC: MvnCodeFE;
  DOCS_FOLDER_ARCHIVER_CREATE_SUCC: MvnCodeFE;
  DOCS_FOLDER_FILE_ARCHIVER_CREATE_SUCC: MvnCodeFE;
  DOCS_FOLDER_CREATE_FAIL: MvnCodeFE;
  DOCS_SHARING_SETTING_DIALOG_HEADER: MvnCodeFE;
  DOCS_ADVANCED_SHARING_SETTING_DIALOG_HEADER: MvnCodeFE;
  DOCS_ARCHIVER_RENAME_DIALOG_DESCRIPTION: MvnCodeFE;
  DOCS_FOLDER_MOVE_SUCC: MvnCodeFE;
  DOCS_FOLDER_MOVE_FAIL: MvnCodeFE;
  DOCS_FOLDER_MOVE_DIALOG_HEADER: MvnCodeFE;
  DOCS_FOLDER_MOVE_DIALOG_TITLE: MvnCodeFE;
  DOCS_FOLDER_MOVE_DIALOG_CONFIRM: MvnCodeFE;
  DOCS_FOLDER_VARIABLES_DIALOG_HEADER: MvnCodeFE;
  DOCS_FOLDER_VARIABLE_DIALOG_HEADER: MvnCodeFE;
  DOCS_FOLDER_VARIABLE_ADD_BTN: MvnCodeFE;
  DOCS_FOLDER_VARIABLE_ADD_SUCC: MvnCodeFE;
  DOCS_FOLDER_VARIABLE_ADD_FAIL: MvnCodeFE;
  DOCS_FOLDER_VARIABLE_SAVE_SUCC: MvnCodeFE;
  DOCS_FOLDER_VARIABLE_SAVE_FAIL: MvnCodeFE;
  DOCS_FOLDER_RENAME_DIALOG_HEADER: MvnCodeFE;
  DOCS_FOLDER_RENAME_DIALOG_TITLE: MvnCodeFE;
  DOCS_FOLDER_RENAME_SUCC: MvnCodeFE;
  DOCS_FOLDER_RENAME_FAIL: MvnCodeFE;
  DOCS_FOLDER_DELETE_HEADER: MvnCodeFE;
  DOCS_FOLDER_DELETE_CONFIRM: MvnCodeFE;
  DOCS_FOLDER_DELETE_SUCC: MvnCodeFE;
  DOCS_FOLDER_DELETE_FAIL: MvnCodeFE;
  DOCS_FOLDER_FILE_MOVE_SUCC: MvnCodeFE;
  DOCS_FOLDER_FILE_MOVE_FAIL: MvnCodeFE;
  DOCS_FOLDER_FILE_DELETE_HEADER: MvnCodeFE;
  DOCS_FOLDER_FILE_DELETE_CONFIRM: MvnCodeFE;
  DOCS_FOLDER_FILE_DELETE_SUCC: MvnCodeFE;
  DOCS_FOLDER_FILE_DELETE_FAIL: MvnCodeFE;
  DOCS_ITEM_DELETE_DESCRIPTION: MvnCodeFE;
  DOCS_ITEM_DELETE_SUCC: MvnCodeFE;
  DOCS_ITEM_MOVE_SUCC: MvnCodeFE;
  DOCS_NO_FOLDER_WARN_TITLE: MvnCodeFE;
  DOCS_NO_FOLDER_WARN_DESCRIPTION: MvnCodeFE;
  DOCS_SUBJECT_DELETE_HEADER: MvnCodeFE;
  DOCS_SUBJECT_DELETE_CONFIRM: MvnCodeFE;
  DOCS_SUBJECTS_DELETE_CONFIRM: MvnCodeFE;
  DOCS_SUBJECT_DELETE_SUCC: MvnCodeFE;
  DOCS_SUBJECTS_DELETE_SUCC: MvnCodeFE;
  DOCS_SUBJECT_DELETE_FAIL: MvnCodeFE;
  DOCS_SUBJECTS_DELETE_FAIL: MvnCodeFE;
  DOCS_ECONSENTS_CANCEL_SUCC: MvnCodeFE;
  DOCS_ECONSENTS_CANCEL_FAIL: MvnCodeFE;
  DOCS_FOLDER_PROPOSAL_CREATE_DISABLED: MvnCodeFE;
  DOCS_FOLDER_VARIABLE_CREATE_DISABLED: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_ADD_SUCC: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_ADD_FAIL: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_HEADER: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_CONFIRM: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_SUCC: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_FAIL: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_URL_COPY_SUCC: MvnCodeFE;
  DOCS_FOLDER_SHARING_EXTERNAL_URL_COPY_FAIL: MvnCodeFE;
  DOCS_FOLDER_SHARING_FILE_SUCC: MvnCodeFE;
  DOCS_FOLDER_SHARING_FOLDER_SUCC: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTING_SAVE_SUCC: MvnCodeFE;
  DOCS_FOLDER_SHARING_FAIL: MvnCodeFE;
  DOCS_FOLDER_SHARING_TOKEN_HEADER: MvnCodeFE;
  DOCS_FOLDER_SHARING_TOKEN_DESCRIPTION: MvnCodeFE;
  DOCS_FOLDER_SHARING_TOKEN_EMAIL_PLACEHOLDER: MvnCodeFE;
  DOCS_FOLDER_SHARING_TOKEN_EMAIL_NOT_AVAILABLE_DESC: MvnCodeFE;
  DOCS_FOLDER_SHARING_TOKEN_CODE_PLACEHOLDER: MvnCodeFE;
  DOCS_FOLDER_SHARING_TOKEN_EMAIL_REQUEST_VERIFY_CODE: MvnCodeFE;
  DOCS_FOLDER_SHARING_TOKEN_PROCEED_VERIFY: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_DYNAMIC_WATERMARK: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_DOWNLOAD_WITH_WATERMARK: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_DOWNLOAD_WITH_PASSWORD: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_IP_WHITELIST: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_ADD: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_NAME_LABEL: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_NAME_PLACEHOLDER: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_IP_ADDR_LABEL: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_IP_ADDR_PLACEHOLDER: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_UPDATE_SUCC: MvnCodeFE;
  DOCS_FOLDER_SHARING_SETTINGS_UPDATE_FAIL: MvnCodeFE;
  DOCS_FOLDER_ARCHIVER_SUBSCRIBE_PLAN: MvnCodeFE;
  DOCS_FOLDER_ECONSENT_ADD_DIALOG_HEADER: MvnCodeFE;
  DOCS_FOLDER_FILE_SENT_ICF_DELETE_CONFIRM: MvnCodeFE;
  DOCS_FOLDER_NO_DATA_TITLE: MvnCodeFE;
  DOCS_FOLDER_NO_DATA_DESCRIPTION: MvnCodeFE;
  DOCS_SHOW_DELETED_ITEM: MvnCodeFE;
} = {
  DOCS_FOLDER_COMMON_CANCEL_NOUN: {
    ko: "취소",
    en: "Cancel",
  },
  DOCS_FOLDER_COMMON_SAVE_NOUN: {
    ko: "저장",
    en: "Save",
  },
  DOCS_FOLDER_COMMON_DELETE_NOUN: {
    ko: "삭제",
    en: "Delete",
  },
  DOCS_FOLDER_COMMON_ADD_NOUN: {
    ko: "추가",
    en: "Add",
  },
  DOCS_FOLDER_COMMON_OK_NOUN: {
    ko: "확인",
    en: "OK",
  },
  DOCS_FOLDER_COMMON_CLOSE_NOUN: {
    ko: "닫기",
    en: "Close",
  },
  DOCS_FOLDER_COMMON_CREATE_NOUN: {
    ko: "생성",
    en: "Create",
  },
  DOCS_FOLDER_ADD_DIALOG_HEADER: {
    ko: "Create new folder",
    en: "Create new folder",
  },
  DOCS_FOLDER_NAME_LABEL: {
    ko: "이름",
    en: "Name",
  },
  DOCS_FOLDER_ARCHIVER_NAME_PLACEHOLDER: {
    ko: "New folder",
    en: "New folder",
  },
  DOCS_FOLDER_ARCHIVER_NAME_LABEL: {
    ko: "Folder name",
    en: "Folder name",
  },
  DOCS_FOLDER_NAME_PLACEHOLDER: {
    ko: "폴더 이름을 입력해 주세요.",
    en: "Please enter the folder name.",
  },
  DOCS_FOLDER_CREATE_SUCC: {
    ko: "폴더 등록에 성공했습니다.",
    en: "You have created a folder successfully.",
  },
  DOCS_FOLDER_ARCHIVER_CREATE_SUCC: {
    ko: "폴더가 생성되었습니다.",
    en: "Folder has been created.",
  },
  DOCS_FOLDER_FILE_ARCHIVER_CREATE_SUCC: {
    ko: "파일이 업로드되었습니다.",
    en: "File has been uploaded.",
  },
  DOCS_FOLDER_CREATE_FAIL: {
    ko: "폴더 등록에 실패했습니다. ({message})",
    en: "Failed to create a folder. ({message})",
  },
  DOCS_SHARING_SETTING_DIALOG_HEADER: {
    ko: "Sharing settings",
    en: "Sharing settings",
  },
  DOCS_ADVANCED_SHARING_SETTING_DIALOG_HEADER: {
    ko: "Advanced settings",
    en: "Advanced settings",
  },
  DOCS_ARCHIVER_RENAME_DIALOG_DESCRIPTION: {
    ko: "새 이름을 입력하세요.",
    en: "Please enter new name.",
  },
  DOCS_FOLDER_MOVE_SUCC: {
    ko: "폴더 이동에 성공했습니다.",
    en: "You have moved a folder successfully.",
  },
  DOCS_FOLDER_MOVE_FAIL: {
    ko: "폴더 이동에 실패했습니다. ({message})",
    en: "Failed to create a folder. ({message})",
  },
  DOCS_FOLDER_MOVE_DIALOG_HEADER: {
    ko: "Move To",
    en: "Move To",
  },
  DOCS_FOLDER_MOVE_DIALOG_TITLE: {
    ko: "다음 위치로 항목을 이동합니다.",
    en: "Move an item to selected location.",
  },
  DOCS_FOLDER_MOVE_DIALOG_CONFIRM: {
    ko: "항목을 이동하면 기존 상위 폴더에 설정한 공유 및 권한이 초기화되고, 선택한 폴더의 공유 및 권한으로 변경됩니다. 항목을 이동하시겠습니까?",
    en: "If you move an item to selected location, the previous sharing and role settings are initialized and changed to settings of the selected folder. Are you sure you want to move an item?",
  },
  DOCS_FOLDER_VARIABLES_DIALOG_HEADER: {
    ko: "Folder Variables",
    en: "Folder Variables",
  },
  DOCS_FOLDER_VARIABLE_DIALOG_HEADER: {
    ko: "Folder Variable",
    en: "Folder Variable",
  },
  DOCS_FOLDER_VARIABLE_ADD_BTN: {
    ko: "Add Variable",
    en: "Add Variable",
  },
  DOCS_FOLDER_VARIABLE_ADD_SUCC: {
    ko: "Variable을 추가하였습니다.",
    en: "You have added variable successfully.",
  },
  DOCS_FOLDER_VARIABLE_ADD_FAIL: {
    ko: "Variable 추가에 실패했습니다. ({message})",
    en: "Failed to create a variable. ({message})",
  },
  DOCS_FOLDER_VARIABLE_SAVE_SUCC: {
    ko: "Variable 정보를 저장하였습니다.",
    en: "You have saved variable information successfully.",
  },
  DOCS_FOLDER_VARIABLE_SAVE_FAIL: {
    ko: "Variable 정보 저장에 실패했습니다. ({message})",
    en: "Failed to save a variable information. ({message})",
  },
  DOCS_FOLDER_RENAME_DIALOG_HEADER: {
    ko: "Rename",
    en: "Rename",
  },
  DOCS_FOLDER_RENAME_DIALOG_TITLE: {
    ko: "새 이름을 입력하세요.",
    en: "Please enter a new name.",
  },
  DOCS_FOLDER_RENAME_SUCC: {
    ko: "이름이 수정되었습니다.",
    en: "Name has been changed.",
  },
  DOCS_FOLDER_RENAME_FAIL: {
    ko: "이름 변경에 실패했습니다. ({message})",
    en: "Failed to rename an item.",
  },
  DOCS_FOLDER_DELETE_HEADER: {
    ko: "Delete",
    en: "Delete",
  },
  DOCS_FOLDER_DELETE_CONFIRM: {
    ko: "'{name}' 폴더를 삭제하시겠습니까?",
    en: "Are you sure you want to delete a folder, '{name}'?",
  },
  DOCS_FOLDER_DELETE_SUCC: {
    ko: "폴더가 삭제되었습니다.",
    en: "You have deleted a folder successfully.",
  },
  DOCS_FOLDER_DELETE_FAIL: {
    ko: "폴더 삭제에 실패했습니다. ({message})",
    en: "Failed to delete a folder. ({message})",
  },
  DOCS_FOLDER_FILE_MOVE_SUCC: {
    ko: "파일 이동에 성공했습니다.",
    en: "You have moved a file successfully.",
  },
  DOCS_FOLDER_FILE_MOVE_FAIL: {
    ko: "파일 이동에 실패했습니다. ({message})",
    en: "Failed to move a file. ({message})",
  },
  DOCS_FOLDER_FILE_DELETE_HEADER: {
    ko: "Delete",
    en: "Delete",
  },
  DOCS_FOLDER_FILE_DELETE_CONFIRM: {
    ko: "'{name}' 파일을 삭제하시겠습니까?",
    en: "Are you sure you want to delete a file, '{name}'?",
  },
  DOCS_ITEM_DELETE_DESCRIPTION: {
    ko: "아이템을 정말 삭제하시겠습니까?",
    en: "Are you sure you want to delete the item?",
  },
  DOCS_ITEM_DELETE_SUCC: {
    ko: "선택된 아이템이 삭제되었습니다.",
    en: "Selected item has been deleted.",
  },
  DOCS_NO_FOLDER_WARN_TITLE: {
    ko: "표시할 폴더가 없습니다.",
    en: "No folder.",
  },
  DOCS_NO_FOLDER_WARN_DESCRIPTION: {
    ko: "새 폴더를 생성하세요.",
    en: "Create new folder.",
  },
  DOCS_ITEM_MOVE_SUCC: {
    ko: "선택된 아이템이 이동되었습니다.",
    en: "Selected item has been moved.",
  },
  DOCS_FOLDER_FILE_DELETE_SUCC: {
    ko: "파일이 삭제되었습니다.",
    en: "You have deleted a file successfully.",
  },
  DOCS_FOLDER_FILE_DELETE_FAIL: {
    ko: "파일 삭제에 실패했습니다. ({message})",
    en: "Failed to delete a file. ({message})",
  },
  DOCS_SUBJECT_DELETE_HEADER: {
    ko: "Delete",
    en: "Delete",
  },
  DOCS_SUBJECT_DELETE_CONFIRM: {
    ko: "'{name}' 연구 대상자를 삭제하시겠습니까?",
    en: "Are you sure you want to delete a subject, '{name}'?",
  },
  DOCS_SUBJECTS_DELETE_CONFIRM: {
    ko: "'{name}'을 포함한 연구 대상자 {count}명을 삭제하시겠습니까?",
    en: "Are you sure you want to delete {count} subjects including '{name}'?",
  },
  DOCS_SUBJECT_DELETE_SUCC: {
    ko: "연구 대상자가 삭제되었습니다.",
    en: "You have deleted a subject successfully.",
  },
  DOCS_SUBJECTS_DELETE_SUCC: {
    ko: "{count}명의 연구 대상자가 삭제되었습니다.",
    en: "{count} research subjects have been deleted.",
  },
  DOCS_SUBJECT_DELETE_FAIL: {
    ko: "연구 대상자 삭제에 실패했습니다. ({message})",
    en: "Failed to delete a subject. ({message})",
  },
  DOCS_SUBJECTS_DELETE_FAIL: {
    ko: "{count}명의 연구 대상자를 삭제하는데 오류가 발생했습니다.",
    en: "Error occurred while deleting {count} research subjects. ({message})",
  },
  DOCS_ECONSENTS_CANCEL_SUCC: {
    ko: "eConsent 서명 취소 요청이 완료 되었습니다. ({total}건 중 {count}건)",
    en: "eConsent signature cancellation requests completed ({count} out of {total}).",
  },
  DOCS_ECONSENTS_CANCEL_FAIL: {
    ko: "eConsent 서명 취소 요청에 실패했습니다. ({message})",
    en: "Failed to cancel eConsent signature cancellation request. ({message})",
  },
  DOCS_FOLDER_PROPOSAL_CREATE_DISABLED: {
    ko: "최상위 폴더에는 Proposal을 생성할 수 없습니다.",
    en: "You cannot create proposals on the top most folder.",
  },
  DOCS_FOLDER_VARIABLE_CREATE_DISABLED: {
    ko: "최상위 폴더에는 Variable을 생성할 수 없습니다.",
    en: "You cannot create variables on the top most folder.",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_ADD_SUCC: {
    ko: "공유되었습니다.",
    en: "Shared.",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_ADD_FAIL: {
    ko: "공유 대상 추가에 실패하였습니다. ({message})",
    en: "Failed to add an email address to sharing. ({message})",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_HEADER: {
    ko: "Delete",
    en: "Delete",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_CONFIRM: {
    ko: "공유된 이메일을 정말 삭제하시겠습니까?",
    en: "Are you sure you want to delete a shared email address?",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_SUCC: {
    ko: "공유 대상이 삭제되었습니다.",
    en: "You have deleted an email to sharing successfully.",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_EMAIL_DELETE_FAIL: {
    ko: "공유 대상 삭제에 실패하였습니다. ({message})",
    en: "Failed to delete an email address to sharing. ({message})",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_URL_COPY_SUCC: {
    ko: "클립보드에 링크가 복사되었습니다.",
    en: "Link copied to clipboard.",
  },
  DOCS_FOLDER_SHARING_EXTERNAL_URL_COPY_FAIL: {
    ko: "외부 접속 URL 복사에 실패하였습니다. ({message})",
    en: "Failed to copy access link to clipboard. ({message})",
  },
  DOCS_FOLDER_SHARING_FOLDER_SUCC: {
    ko: "폴더를 공유하였습니다.",
    en: "You have shared a folder successfully.",
  },
  DOCS_FOLDER_SHARING_FILE_SUCC: {
    ko: "파일을 공유하였습니다.",
    en: "You have shared a file successfully.",
  },
  DOCS_FOLDER_SHARING_SETTING_SAVE_SUCC: {
    ko: "공유 설정이 변경되었습니다.",
    en: "Sharing Setting is updated successfully",
  },
  DOCS_FOLDER_SHARING_FAIL: {
    ko: "공유에 실패했습니다. ({message})",
    en: "Failed to share a file. ({message})",
  },
  DOCS_FOLDER_SHARING_TOKEN_HEADER: {
    ko: "이메일 인증",
    en: "Email Verification",
  },
  DOCS_FOLDER_SHARING_TOKEN_DESCRIPTION: {
    ko: "문서를 공유받은 이메일을 입력해 주세요.",
    en: "Please enter your email address.",
  },
  DOCS_FOLDER_SHARING_TOKEN_EMAIL_PLACEHOLDER: {
    ko: "이메일 주소를 입력해 주세요.",
    en: "Please enter your email address.",
  },
  DOCS_FOLDER_SHARING_TOKEN_EMAIL_NOT_AVAILABLE_DESC: {
    ko: "입력하신 이메일 주소({email})는 접근 권한이 없습니다.",
    en: "You have no access granted for email address. ({email})",
  },
  DOCS_FOLDER_SHARING_TOKEN_CODE_PLACEHOLDER: {
    ko: "인증코드를 입력해 주세요.",
    en: "Please enter verification code.",
  },
  DOCS_FOLDER_SHARING_TOKEN_EMAIL_REQUEST_VERIFY_CODE: {
    ko: "인증코드 요청",
    en: "Request Verification Code",
  },
  DOCS_FOLDER_SHARING_TOKEN_PROCEED_VERIFY: {
    ko: "인증하기",
    en: "Verify",
  },
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_DYNAMIC_WATERMARK: {
    ko: "Dynamic Watermark 사용",
    en: "Enable Dynamic Watermark",
  },
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_DOWNLOAD_WITH_WATERMARK: {
    ko: "Viewer 파일 다운로드시 Watermark 포함",
    en: "Include Watermark Viewer File Download",
  },
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_DOWNLOAD_WITH_PASSWORD: {
    ko: "Viewer 다운로드 파일 자동 생성 비밀번호 사용",
    en: "Enable Auto-generated Password for Viewer Downloaded File",
  },
  DOCS_FOLDER_SHARING_SETTINGS_ENABLE_IP_WHITELIST: {
    ko: "IP Whitelist 접근 제어 사용",
    en: "Enable IP Whitelist Access Control",
  },
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_ADD: {
    ko: "IP Whitelist 추가",
    en: "Add IP Whitelist",
  },
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_NAME_LABEL: {
    ko: "Name",
    en: "Name",
  },
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_NAME_PLACEHOLDER: {
    ko: "이름을 입력해 주세요.",
    en: "Please enter name.",
  },
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_IP_ADDR_LABEL: {
    ko: "IP Address",
    en: "IP Address",
  },
  DOCS_FOLDER_SHARING_SETTINGS_IP_WHITELIST_IP_ADDR_PLACEHOLDER: {
    ko: "e.g. 0.0.0.0",
    en: "e.g. 0.0.0.0",
  },
  DOCS_FOLDER_SHARING_SETTINGS_UPDATE_SUCC: {
    ko: "변경사항이 저장되었습니다.",
    en: "Change has been saved.",
  },
  DOCS_FOLDER_SHARING_SETTINGS_UPDATE_FAIL: {
    ko: "공유 설정 저장에 실패하였습니다. ({message})",
    en: "Failed to update sharing settings. ({message})",
  },
  DOCS_FOLDER_ARCHIVER_SUBSCRIBE_PLAN: {
    ko: "Archiver를 사용하기 위해서는\nStandard + Archiver 혹은 Pro Plan을 구독해주세요.",
    en: "If you want to use Archiver,\nplease subscribe Standard + Archiver or Pro Plan.",
  },
  DOCS_FOLDER_ECONSENT_ADD_DIALOG_HEADER: {
    ko: "New Folder",
    en: "New Folder",
  },
  DOCS_FOLDER_FILE_SENT_ICF_DELETE_CONFIRM: {
    ko: "전송 이력이 있는 전자동의서입니다. ",
    en: "This ICF has already sent. ",
  },
  DOCS_FOLDER_NO_DATA_TITLE: {
    ko: "표시할 아이템이 없습니다.",
    en: "No items available.",
  },
  DOCS_FOLDER_NO_DATA_DESCRIPTION: {
    ko: "새 아이템을 추가해주세요.",
    en: "Please add a new item.",
  },
  DOCS_SHOW_DELETED_ITEM: {
    ko: "삭제된 항목 보기",
    en: "Show deleted item",
  },
};

MvnCodeInitiate(DOCS_FOLDER_LANG);
