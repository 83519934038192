import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_USER_LANG: {
  CDMS_STUDY_USER_BREADCRUMB: MvnCodeFE;
  CDMS_STUDY_USER_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_USER_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_USER_ROLE_NOT_FOUND_ERROR: MvnCodeFE;
  CDMS_STUDY_USER_UNASSIGNED_ROLE_ACCESS_ERROR: MvnCodeFE;
  CDMS_STUDY_USER_FULL_NAME_REQUIRED: MvnCodeFE;
  CDMS_STUDY_USER_ACTIVATE_DATE_REQUIRED: MvnCodeFE;
  CDMS_STUDY_USER_LIST_DOWNLOAD_FAIL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_SITE_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_NAME_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_EMAIL_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_USER_TYPE_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_ROLE_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_CERTIFICATE_ISSUE_DATE_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_ACTIVATION_STATUS_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_ACCOUNT_ACTIVATION_DATE_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_ACCOUNT_TERMINATION_DATE_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_LAST_EMAIL_SENT_DATE_LABEL: MvnCodeFE;
  CDMS_STUDY_USER_LIST_SEND_EMAIL_LABEL: MvnCodeFE;
} = {
  CDMS_STUDY_USER_BREADCRUMB: {
    ko: "Users",
    en: "Users",
  },
  CDMS_STUDY_USER_PAGE_TITLE: {
    ko: "Manage User",
    en: "Manage User",
  },
  CDMS_STUDY_USER_PAGE_DESC: {
    ko: "등록된 Study 사용자를 확인하고 관리할 수 있습니다.",
    en: "Inquire and manage study Users.",
  },
  CDMS_STUDY_USER_ROLE_NOT_FOUND_ERROR: {
    ko: "존재하지 않는 Role로 접근하였습니다. Study List 화면으로 이동합니다. ({message})",
    en: "You have accessed the study with a role that does not exist. Returning to the study list. ({message})",
  },
  CDMS_STUDY_USER_UNASSIGNED_ROLE_ACCESS_ERROR: {
    ko: "부여받지 않은 Role로 접근하였습니다. Study List 화면으로 이동합니다. ({message})",
    en: "You have accessed the study with a role not assigned. Returning to the study list. ({message})",
  },
  CDMS_STUDY_USER_FULL_NAME_REQUIRED: {
    ko: "Full Name은 필수 입력 값입니다.",
    en: "Full Name is mandatory.",
  },
  CDMS_STUDY_USER_ACTIVATE_DATE_REQUIRED: {
    ko: "계정 사용 시작 일자는 필수 입력 값입니다.",
    en: "Activate Date is mandatory.",
  },
  CDMS_STUDY_USER_LIST_DOWNLOAD_FAIL: {
    ko: "사용자 목록 다운로드에 실패하였습니다. ({error})",
    en: "User list download has been failed. ({error})",
  },
  CDMS_STUDY_USER_LIST_SITE_LABEL: {
    ko: "기관 (코드)",
    en: "Site (Code)",
  },
  CDMS_STUDY_USER_LIST_NAME_LABEL: {
    ko: "이름",
    en: "Name",
  },
  CDMS_STUDY_USER_LIST_EMAIL_LABEL: {
    ko: "이메일",
    en: "E-Mail",
  },
  CDMS_STUDY_USER_LIST_USER_TYPE_LABEL: {
    ko: "사용자 유형",
    en: "User Type",
  },
  CDMS_STUDY_USER_LIST_ROLE_LABEL: {
    ko: "역할",
    en: "Role",
  },
  CDMS_STUDY_USER_LIST_CERTIFICATE_ISSUE_DATE_LABEL: {
    ko: "인증서 발급일",
    en: "Certificate Issue Date",
  },
  CDMS_STUDY_USER_LIST_ACTIVATION_STATUS_LABEL: {
    ko: "활성화 여부",
    en: "Activation Status",
  },
  CDMS_STUDY_USER_LIST_ACCOUNT_ACTIVATION_DATE_LABEL: {
    ko: "계정 시작일",
    en: "Account Activation Date",
  },
  CDMS_STUDY_USER_LIST_ACCOUNT_TERMINATION_DATE_LABEL: {
    ko: "계정 만료일",
    en: "Account Termination Date",
  },
  CDMS_STUDY_USER_LIST_LAST_EMAIL_SENT_DATE_LABEL: {
    ko: "최근 메일 전송일",
    en: "Last Sent Date",
  },
  CDMS_STUDY_USER_LIST_SEND_EMAIL_LABEL: {
    ko: "메일 전송",
    en: "Send E-Mail",
  },
};

MvnCodeInitiate(CDMS_STUDY_USER_LANG);
