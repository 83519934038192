import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_REPORT_CODE: {
  CDMS_STUDY_REPORT_INVALID_CANCELLED_REPORT: MvnCodeBE;
} = {
  CDMS_STUDY_REPORT_INVALID_CANCELLED_REPORT: {
    status: 409,
    ko: "취소 처리된 보고서입니다.",
    en: "Report has been cancelled.",
  },
};

MvnCodeInitiate(CDMS_STUDY_REPORT_CODE);
