import { MvnCodeFE } from "./../const";
import { MvnCodeInitiate } from "../../lib/const";

export const CDMS_STUDY_INDEPENDENT_EVALUATION_LANG: {
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_FILE_DOWNLOAD_FAIL: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_ON: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_ON_FAIL: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_OFF: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_OFF_FAIL: MvnCodeFE;
} = {
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_TITLE: {
    ko: "Independent Evaluation",
    en: "Independent Evaluation",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_DESC: {
    ko: "독립적 평가 대상자를 확인하고 평가를 진행할 수 있습니다.",
    en: "View independent evaluation subjects and proceed with the evaluation.",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_FILE_DOWNLOAD_FAIL: {
    ko: "파일 다운로드에 실패하였습니다.",
    en: "Failed to download the file.",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_ON: {
    ko: "전자서명이 성공적으로 처리되었습니다.",
    en: "E-Signature processed successfully.",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_OFF: {
    ko: "전자서명이 해제되었습니다.",
    en: "E-Signature cancelled successfully.",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_ON_FAIL: {
    ko: "전자서명 처리에 실패했습니다. ({message})",
    en: "Failed to E-Signature. ({message})",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_CRF_ESIGN_OFF_FAIL: {
    ko: "전자서명 해제에 실패했습니다. ({message})",
    en: "Failed to E-Signature cancel. ({message})",
  },
};

MvnCodeInitiate(CDMS_STUDY_INDEPENDENT_EVALUATION_LANG);
