import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_VALIDATION_LANG: {
  VALIDATION_IN_PROGRESS_MESSAGE: MvnCodeFE;
  VALIDATION_ISSUE_REPORT: MvnCodeFE;
  VALIDATION_ISSUE_CHECK: MvnCodeFE;
  VALIDATION_ISSUE_REPORT_EXPORT_SUCCEED: MvnCodeFE;
  VALIDATION_ISSUE_REPORT_EXPORT_FAILED: MvnCodeFE;
  VALIDATION_ISSUE_CHECK_SUCCEED: MvnCodeFE;
  VALIDATION_ISSUE_CHECK_FAILED: MvnCodeFE;
} = {
  VALIDATION_IN_PROGRESS_MESSAGE: {
    ko: "검증 진행 중입니다.\n종료될 때까지 기다려 주세요.",
    en: "Validation is in progress.\nPlease wait until it is complete.",
  },

  VALIDATION_ISSUE_REPORT: {
    ko: "이슈 보고서",
    en: "Issue Report",
  },

  VALIDATION_ISSUE_CHECK: {
    ko: "유효성 검사",
    en: "Validation",
  },

  VALIDATION_ISSUE_REPORT_EXPORT_SUCCEED: {
    ko: "결과 패키지 압축 파일 다운로드를 완료했습니다.",
    en: "The result package zip file has been successfully downloaded.",
  },

  VALIDATION_ISSUE_REPORT_EXPORT_FAILED: {
    ko: "결과 패키지 압축 파일 다운로드에 실패했습니다.",
    en: "Failed to download the result package zip file.",
  },

  VALIDATION_ISSUE_CHECK_SUCCEED: {
    ko: "유효성 검사를 완료했습니다.",
    en: "Validation has been completed.",
  },

  VALIDATION_ISSUE_CHECK_FAILED: {
    ko: "유효성 검사에 실패했습니다.",
    en: "Failed to validation.",
  },
};

MvnCodeInitiate(CONVERTER_VALIDATION_LANG);
