import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_REPORT_INFO_CODE: {
  CDMS_STUDY_REPORT_INFO_SUBMITTED_EXIST: MvnCodeBE;
} = {
  CDMS_STUDY_REPORT_INFO_SUBMITTED_EXIST: {
    status: 400,
    ko: "제출된 보고서가 존재합니다.",
    en: "A submitted report exists.",
  },
};

MvnCodeInitiate(CDMS_STUDY_REPORT_INFO_CODE);
