import { CONVERTER_COMMON_LANG } from "./common";
import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_MY_DICTIONARY_LANG: {
  SDTM_TAB: MvnCodeFE;
  MY_DICTIONARY_TAB: MvnCodeFE;
  SDTM_LIST_EMPTY: MvnCodeFE;
  SDTM_LIST_TOOLTIP_ADD: MvnCodeFE;
  SDTM_LIST_TOAST_TERMINOLOGY_ADD_SUCCEED: MvnCodeFE;
  SDTM_LIST_TOAST_TERMINOLOGY_ADD_FAIL: MvnCodeFE;
  MY_DICTIONARY_LIST_EMPTY: MvnCodeFE;
  MY_DICTIONARY_LIST_MENU_UPDATE: MvnCodeFE;
  MY_DICTIONARY_LIST_MENU_DELETE: MvnCodeFE;
  MY_DICTIONARY_LIST_FOOTER_BUTTON_MANAGEMENT: MvnCodeFE;
  MY_DICTIONARY_LIST_FOOTER_BUTTON_EXPORT: MvnCodeFE;
  MY_DICTIONARY_TOAST_EXPORT_SUCCESS: MvnCodeFE;
  MY_DICTIONARY_TOAST_EXPORT_FAIL: MvnCodeFE;
  MY_DICTIONARY_DIALOG_EMPTY: MvnCodeFE;
  MY_DICTIONARY_DIALOG_TITLE: MvnCodeFE;
  MY_DICTIONARY_DIALOG_ADD_GROUP_PLACEHOLDER: MvnCodeFE;
  MY_DICTIONARY_DIALOG_ADD_GROUP_BUTTON: MvnCodeFE;
  MY_DICTIONARY_DIALOG_FORM_GROUP_PLACEHOLDER: MvnCodeFE;
  MY_DICTIONARY_DIALOG_FORM_NCI_CODE_PLACEHOLDER: MvnCodeFE;
  MY_DICTIONARY_DIALOG_FORM_SUBMISSION_VALUE_PLACEHOLDER: MvnCodeFE;
  MY_DICTIONARY_DIALOG_FORM_SYNONYM_PLACEHOLDER: MvnCodeFE;
  MY_DICTIONARY_DIALOG_FORM_DECODED_VALUE_PLACEHOLDER: MvnCodeFE;
  MY_DICTIONARY_DIALOG_FORM_RANK_PLACEHOLDER: MvnCodeFE;
  MY_DICTIONARY_DIALOG_ADD_TERM_DEFAULT_NAME: MvnCodeFE;
  MY_DICTIONARY_DIALOG_DRAFTED_ITEM: MvnCodeFE;
  MY_DICTIONARY_DIALOG_EDITED_ITEM: MvnCodeFE;
  MY_DICTIONARY_DIALOG_DRAFTING_ITEM: MvnCodeFE;
  MY_DICTIONARY_DIALOG_EDITING_ITEM: MvnCodeFE;
  MY_DICTIONARY_TOAST_TERMINOLOGY_EDIT_SUCCEED: MvnCodeFE;
  MY_DICTIONARY_TOAST_TERMINOLOGY_EDIT_FAIL: MvnCodeFE;
  MY_DICTIONARY_DIALOG_DELETE_TERMINOLOGY: MvnCodeFE;
  MY_DICTIONARY_TOAST_TERMINOLOGY_DELETE_SUCCEED: MvnCodeFE;
  MY_DICTIONARY_TOAST_TERMINOLOGY_DELETE_FAIL: MvnCodeFE;
  MY_DICTIONARY_DIALOG_CLOSE_ALERT_TITLE: MvnCodeFE;
  MY_DICTIONARY_DIALOG_CLOSE_ALERT_DESCRIPTION: MvnCodeFE;

  MY_DICTIONARY_DIALOG_DELETE: MvnCodeFE;
  MY_DICTIONARY_DIALOG_CONFIRM: MvnCodeFE;
  MY_DICTIONARY_DIALOG_CANCEL: MvnCodeFE;
  MY_DICTIONARY_DIALOG_SAVE: MvnCodeFE;
} = {
  SDTM_TAB: {
    ko: "SDTM CT",
    en: "SDTM CT",
  },
  MY_DICTIONARY_TAB: {
    ko: "My Dictionary",
    en: "My Dictionary",
  },
  SDTM_LIST_EMPTY: {
    ko: "조회된 데이터가 없습니다.",
    en: "No data found.",
  },
  SDTM_LIST_TOOLTIP_ADD: {
    ko: "My Dictionary에 추가",
    en: "Archive to My Dictionary",
  },
  SDTM_LIST_TOAST_TERMINOLOGY_ADD_SUCCEED: {
    ko: "My Dictionary에 추가했습니다.",
    en: "It has been added to my dictionary.",
  },
  SDTM_LIST_TOAST_TERMINOLOGY_ADD_FAIL: {
    ko: "My Dictionary 추가에 실패했습니다.",
    en: "Failed to add to my dictionary.",
  },
  MY_DICTIONARY_LIST_EMPTY: {
    ko: "추가된 데이터가 없습니다.",
    en: "There is no data.",
  },
  MY_DICTIONARY_LIST_MENU_UPDATE: CONVERTER_COMMON_LANG.EDIT_VERB,
  MY_DICTIONARY_LIST_MENU_DELETE: CONVERTER_COMMON_LANG.DELETE_VERB,
  MY_DICTIONARY_LIST_FOOTER_BUTTON_MANAGEMENT: {
    ko: "용어 관리",
    en: "CT Management",
  },
  MY_DICTIONARY_LIST_FOOTER_BUTTON_EXPORT: {
    ko: "내보내기",
    en: "Export",
  },
  MY_DICTIONARY_TOAST_EXPORT_SUCCESS: {
    ko: "내보내기를 완료했습니다.",
    en: "My Dictionary has been exported.",
  },
  MY_DICTIONARY_TOAST_EXPORT_FAIL: {
    ko: "내보내기에 실패했습니다.",
    en: "Failed to export the My Dictionary.",
  },
  MY_DICTIONARY_DIALOG_TITLE: {
    ko: "용어 관리",
    en: "CT Management",
  },
  MY_DICTIONARY_DIALOG_EMPTY: {
    ko: "등록된 코드가 없습니다.",
    en: "Code not found.",
  },
  MY_DICTIONARY_DIALOG_ADD_GROUP_PLACEHOLDER: {
    ko: "코드리스트명(그룹 이름) 입력",
    en: "Enter the Codelist name(Group name)",
  },
  MY_DICTIONARY_DIALOG_ADD_GROUP_BUTTON: {
    ko: "추가",
    en: "Add",
  },
  MY_DICTIONARY_DIALOG_FORM_GROUP_PLACEHOLDER: {
    ko: "코드리스트명(그룹명) 입력",
    en: "CodelistName(Group name)",
  },
  MY_DICTIONARY_DIALOG_FORM_NCI_CODE_PLACEHOLDER: {
    ko: "CDISC/NCI Code",
    en: "CDISC/NCI Code",
  },
  MY_DICTIONARY_DIALOG_FORM_SUBMISSION_VALUE_PLACEHOLDER: {
    ko: "제출 용어",
    en: "Terminology for submission",
  },
  MY_DICTIONARY_DIALOG_FORM_SYNONYM_PLACEHOLDER: {
    ko: "제출 용어에 대한 동의어",
    en: "A word or phrase with the same meaning for a submission value.",
  },
  MY_DICTIONARY_DIALOG_FORM_DECODED_VALUE_PLACEHOLDER: {
    ko: "사용자 정의 용어",
    en: "The decoded value becoming the display value.",
  },
  MY_DICTIONARY_DIALOG_FORM_RANK_PLACEHOLDER: {
    ko: "등급",
    en: "Rank",
  },
  MY_DICTIONARY_DIALOG_ADD_TERM_DEFAULT_NAME: {
    ko: "제목 없음",
    en: "Untitled",
  },
  MY_DICTIONARY_DIALOG_DRAFTED_ITEM: {
    ko: "작성됨",
    en: "Drafted",
  },
  MY_DICTIONARY_DIALOG_EDITED_ITEM: {
    ko: "수정됨",
    en: "Edited",
  },
  MY_DICTIONARY_DIALOG_DRAFTING_ITEM: {
    ko: "작성중",
    en: "Drafting",
  },
  MY_DICTIONARY_DIALOG_EDITING_ITEM: {
    ko: "수정중",
    en: "Editing",
  },
  MY_DICTIONARY_TOAST_TERMINOLOGY_EDIT_SUCCEED: {
    ko: "그룹 또는 단일 용어를 수정했습니다.",
    en: "The codelist(group) or terminology has been modified.",
  },
  MY_DICTIONARY_TOAST_TERMINOLOGY_EDIT_FAIL: {
    ko: "그룹 또는 단일 용어 수정에 실패했습니다.",
    en: "Failed to modify the codelist(group) or terminology.",
  },
  MY_DICTIONARY_DIALOG_DELETE_TERMINOLOGY: {
    ko: "선택한 그룹 또는 단일 용어를 삭제하시겠습니까?",
    en: "Are you sure you want to delete the selected codelist(group) or terminology?",
  },
  MY_DICTIONARY_TOAST_TERMINOLOGY_DELETE_SUCCEED: {
    ko: "그룹 또는 단일 용어를 삭제했습니다.",
    en: "The codelist(group) or terminology has been deleted.",
  },
  MY_DICTIONARY_TOAST_TERMINOLOGY_DELETE_FAIL: {
    ko: "그룹 또는 단일 용어 삭제에 실패했습니다.",
    en: "Failed to delete the codelist(group) or terminology.",
  },
  MY_DICTIONARY_DIALOG_CLOSE_ALERT_TITLE: {
    ko: "저장하지 않고 편집창을 닫으시겠습니까?",
    en: "Would you like to close the editing window without saving?",
  },
  MY_DICTIONARY_DIALOG_CLOSE_ALERT_DESCRIPTION: {
    ko: "‘확인’ 선택시 저장하지 않은 편집 내용은 스터디에 반영되지 않습니다. \n편집 내용의 저장을 원하시면 ‘취소’ 후 ‘저장’을 선택해 주시기 바랍니다.",
    en: "When you select 'OK', unsaved edits will not be reflected in the study. \nIf you want to save the edits, please select 'Cancel' and then 'Save'",
  },
  MY_DICTIONARY_DIALOG_DELETE: CONVERTER_COMMON_LANG.DELETE_VERB,
  MY_DICTIONARY_DIALOG_CONFIRM: CONVERTER_COMMON_LANG.CONFIRM_VERB,
  MY_DICTIONARY_DIALOG_SAVE: CONVERTER_COMMON_LANG.SAVE_VERB,
  MY_DICTIONARY_DIALOG_CANCEL: CONVERTER_COMMON_LANG.CANCEL_VERB,
};
//
MvnCodeInitiate(CONVERTER_MY_DICTIONARY_LANG);
