import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_STUDY_REPORT_LANG: {
  ALL_ACTIONS_VERB: MvnCodeFE;
  ALL_TIME_VERB: MvnCodeFE;
  LAST_24_HOURS: MvnCodeFE;
  LAST_7_DAYS: MvnCodeFE;
  LAST_30_DAYS: MvnCodeFE;
  CUSTOM_TIME: MvnCodeFE;
  LIST_EMPTY: MvnCodeFE;
  EXPORT_BUTTON_TITLE: MvnCodeFE;
  EXPORT_TOAST_SUCCEED: MvnCodeFE;
  EXPORT_TOAST_FAIL: MvnCodeFE;
} = {
  ALL_ACTIONS_VERB: {
    ko: "모든 활동",
    en: "All Actions",
  },
  ALL_TIME_VERB: {
    ko: "전체 기간",
    en: "All Time",
  },
  LAST_24_HOURS: {
    ko: "24시간 전",
    en: "Last 24 Hours",
  },
  LAST_7_DAYS: {
    ko: "7일 전",
    en: "Last 7 Days",
  },
  LAST_30_DAYS: {
    ko: "30일 전",
    en: "Last 30 Days",
  },
  CUSTOM_TIME: {
    ko: "기간 설정",
    en: "Custom Time",
  },
  LIST_EMPTY: {
    ko: "등록된 데이터가 없습니다.",
    en: "No data has been registered.",
  },
  EXPORT_BUTTON_TITLE: {
    ko: "내보내기",
    en: "Export",
  },
  EXPORT_TOAST_SUCCEED: {
    ko: "내보내기를 완료했습니다.",
    en: "Export has been completed.",
  },
  EXPORT_TOAST_FAIL: {
    ko: "내보내기에 실패했습니다.",
    en: "Failed to export.",
  },
};

MvnCodeInitiate(CONVERTER_STUDY_REPORT_LANG);
