import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_QUERY_LANG: {
  CDMS_REPLY: MvnCodeFE;
  CDMS_CLOSE: MvnCodeFE;
  CDMS_QUERY_REPLY: MvnCodeFE;
  CDMS_QUERY_CLOSE: MvnCodeFE;
  CDMS_QUERY_REPLY_INPUT_PLACEHOLDER: MvnCodeFE;
  CDMS_QUERY_CLOSE_INPUT_PLACEHOLDER: MvnCodeFE;
  CDMS_QUERY_BULK_REPLY_FAIL: MvnCodeFE;
  CDMS_QUERY_BULK_CLOSE_FAIL: MvnCodeFE;
} = {
  CDMS_REPLY: {
    ko: "응답",
    en: "Reply",
  },
  CDMS_CLOSE: {
    ko: "종료",
    en: "Close",
  },
  CDMS_QUERY_REPLY: {
    ko: "쿼리 응답",
    en: "Query Reply",
  },
  CDMS_QUERY_CLOSE: {
    ko: "쿼리 종료",
    en: "Close Query",
  },
  CDMS_QUERY_REPLY_INPUT_PLACEHOLDER: {
    ko: "쿼리 응답 내용을 입력해 주세요.",
    en: "Please enter the content of the query message.",
  },
  CDMS_QUERY_CLOSE_INPUT_PLACEHOLDER: {
    ko: "쿼리 종료 내용을 입력해 주세요.",
    en: "Please enter the content of the query message.",
  },
  CDMS_QUERY_BULK_REPLY_FAIL: {
    ko: "일괄 응답에 실패하였습니다. ({error})",
    en: "Batch query response has been failed. ({error})",
  },
  CDMS_QUERY_BULK_CLOSE_FAIL: {
    ko: "일괄 종료에 실패하였습니다. ({error})",
    en: "Batch Query close has been failed. ({error})",
  },
};

MvnCodeInitiate(CDMS_QUERY_LANG);
