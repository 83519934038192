import { CONVERTER_COMMON_LANG } from "./common";
import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_STUDY_IMPORT_LANG: {
  LIST_EMPTY_TITLE: MvnCodeFE;
  LIST_EMPTY_DESCRIPTION: MvnCodeFE;
  LIST_LABEL_SEQ: MvnCodeFE;
  LIST_LABEL_TYPE: MvnCodeFE;
  LIST_LABEL_FILE_NAME: MvnCodeFE;
  LIST_LABEL_CREATED: MvnCodeFE;
  LIST_LABEL_UPDATED: MvnCodeFE;
  LIST_LABEL_STATUS: MvnCodeFE;
  LIST_LABEL_SIZE: MvnCodeFE;
  LIST_MENU_DELETE: MvnCodeFE;
  LIST_FOOTER_BUTTON: MvnCodeFE;
  TOAST_FILE_UPLOAD_SUCCEED: MvnCodeFE;
  TOAST_FILE_UPLOAD_FAIL: MvnCodeFE;
  TOAST_FILE_DELETE_SUCCEED: MvnCodeFE;
  TOAST_FILE_DELETE_FAIL: MvnCodeFE;
  TOAST_GENERAL_FILE_UPLOAD_FAIL: MvnCodeFE;
  DIALOG_DELETE_TITLE: MvnCodeFE;
  DIALOG_DELETE_DESCRIPTION: MvnCodeFE;
  DIALOG_DELETE_ALERT_TITLE: MvnCodeFE;
  DIALOG_DELETE_ALERT_DESCRIPTION: MvnCodeFE;
  DIALOG_VALIDATION_ALERT_TITLE: MvnCodeFE;
  DIALOG_VALIDATION_ALERT_DESCRIPTION: MvnCodeFE;
  DIALOG_VALIDATION_ALERT_ALL_CHECKBOX: MvnCodeFE;
  DIALOG_VALIDATION_HELPER_TEXT: MvnCodeFE;
  DIALOG_CANCEL: MvnCodeFE;
  DIALOG_CONFIRM: MvnCodeFE;
} = {
  LIST_EMPTY_TITLE: {
    ko: "사용할 수 있는 파일이 없습니다.",
    en: "File not found.",
  },
  LIST_EMPTY_DESCRIPTION: {
    ko: "추가 버튼을 눌러 파일을 업로드해 주세요.",
    en: "Press the Add button to upload the file(s).",
  },
  LIST_LABEL_SEQ: {
    ko: "No.",
    en: "No.",
  },
  LIST_LABEL_TYPE: {
    ko: "분류",
    en: "Type",
  },
  LIST_LABEL_FILE_NAME: {
    ko: "파일명",
    en: "File Name",
  },
  LIST_LABEL_CREATED: {
    ko: "최초 업로드 날짜/시간",
    en: "Initial upload Date/Time",
  },
  LIST_LABEL_UPDATED: {
    ko: "최근 업로드 날짜/시간",
    en: "Last uploaded Date/Time",
  },
  LIST_LABEL_STATUS: {
    ko: "상태",
    en: "Status",
  },
  LIST_LABEL_SIZE: {
    ko: "파일 용량",
    en: "Size",
  },
  LIST_MENU_DELETE: CONVERTER_COMMON_LANG.DELETE_VERB,
  LIST_FOOTER_BUTTON: CONVERTER_COMMON_LANG.ADD_VERB,

  TOAST_FILE_UPLOAD_SUCCEED: {
    ko: "{name} 파일 등록을 완료했습니다.",
    en: "{name} has been imported.",
  },
  TOAST_FILE_UPLOAD_FAIL: {
    ko: "{name} 파일 등록에 실패했습니다.",
    en: "Failed to import {name}",
  },
  TOAST_GENERAL_FILE_UPLOAD_FAIL: {
    ko: "파일 등록에 실패했습니다.",
    en: "Failed to import",
  },
  TOAST_FILE_DELETE_SUCCEED: {
    ko: "파일을 삭제했습니다.",
    en: "The file has been deleted.",
  },
  TOAST_FILE_DELETE_FAIL: {
    ko: "파일 삭제에 실패했습니다.",
    en: "Failed to delete the file.",
  },
  DIALOG_DELETE_TITLE: CONVERTER_COMMON_LANG.DELETE_VERB,
  DIALOG_DELETE_DESCRIPTION: {
    ko: "파일을 삭제하시겠습니까?",
    en: "Confirm deletion of the file?",
  },
  DIALOG_DELETE_ALERT_TITLE: CONVERTER_COMMON_LANG.DELETE_VERB,
  DIALOG_DELETE_ALERT_DESCRIPTION: {
    ko: "현재 사용 중인 파일입니다.\n삭제할 경우 해당 흐름에 영향을 줄 수 있습니다.\n\n삭제하시겠습니까?",
    en: "This file is currently in use.\nDeleting it may disrupt its Dataflow.\n\nDo you want to proceed with deletion?",
  },
  DIALOG_VALIDATION_ALERT_TITLE: {
    ko: "파일 업로드 경고",
    en: "File Upload Warning",
  },
  DIALOG_VALIDATION_ALERT_DESCRIPTION: {
    ko: "중복파일 및 형식에 맞지 않는 파일 목록을 확인해 주시기 바랍니다.",
    en: "Please review the list of duplicate files and files with incompatible formats.",
  },
  DIALOG_VALIDATION_ALERT_ALL_CHECKBOX: {
    ko: "중복파일 모두 덮어쓰기",
    en: "Replace all duplicate files",
  },
  DIALOG_VALIDATION_HELPER_TEXT: {
    ko: "파일 형식이 적합하지 않습니다.",
    en: "The file format is incompatible.",
  },
  DIALOG_CONFIRM: CONVERTER_COMMON_LANG.CONFIRM_VERB,
  DIALOG_CANCEL: CONVERTER_COMMON_LANG.CANCEL_VERB,
};

MvnCodeInitiate(CONVERTER_STUDY_IMPORT_LANG);
