import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_SUBJECT_GROUP_CODE: {
  CDMS_STUDY_SUBJECT_GROUP_NOT_FOUND: MvnCodeBE;
  CDMS_STUDY_SUBJECT_GROUP_NO_CHANGE: MvnCodeBE;
} = {
  CDMS_STUDY_SUBJECT_GROUP_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study Subject Group 정보를 찾을 수 없습니다.",
    en: "Requested study subject group could not be found.",
  },
  CDMS_STUDY_SUBJECT_GROUP_NO_CHANGE: {
    status: 400,
    ko: "변경된 Study Subject Group 정보를 찾을 수 없습니다.",
    en: "Changed study subject group could not be found.",
  },
};

MvnCodeInitiate(CDMS_STUDY_SUBJECT_GROUP_CODE);
