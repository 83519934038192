import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CONVERTER_DEFINEXML_CODE: {
  CONVERTER_DEFINEXML_VALIDATION_ERROR: MvnCodeBE;
} = {
  CONVERTER_DEFINEXML_VALIDATION_ERROR: {
    status: 409,
    ko: "{attribute} 값이 유효하지 않습니다.",
    en: "{attribute} value is not valid.",
  },
};

MvnCodeInitiate(CONVERTER_DEFINEXML_CODE);
