import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_ROOM_QUESTION_LANG: {
  // Description
  LIMIT_USAGE_DESCRIPTION: MvnCodeFE;
  DELETE_THREAD_DESCRIPTION_1: MvnCodeFE;
  DELETE_THREAD_DESCRIPTION_2: MvnCodeFE;
  DELETE_TEAM_DESCRIPTION: MvnCodeFE;
  EMPTY_QNA_DESCRIPTION: MvnCodeFE;
  EMPTY_QNA_SETTING_DESCRIPTION: MvnCodeFE;
  MARK_AS_COMPLETE_DESCRIPTION_1: MvnCodeFE;
  MARK_AS_COMPLETE_DESCRIPTION_2: MvnCodeFE;
  QUESTION_DRAFTER_DESCRIPTION: MvnCodeFE;
  QUESTION_SUBMITTER_DESCRIPTION: MvnCodeFE;
  EXPERT_DESCRIPTION: MvnCodeFE;
  ANSWER_COORDINATOR_DESCRIPTION: MvnCodeFE;
  ONLY_MANAGE_QNA_WARNING_DESCRIPTION: MvnCodeFE;

  // Title
  QNA_SETTINGS_TITLE: MvnCodeFE;
  QNA_DETAIL_TITLE: MvnCodeFE;
  EMPTY_QNA_SETTING: MvnCodeFE;
  EMPTY_QNA_TITLE: MvnCodeFE;
  CREATE_QUESTION_TITLE: MvnCodeFE;
  ADD_QUESTION_TITLE: MvnCodeFE;
  NEW_QUESTION_TITLE: MvnCodeFE;
  DOWNLOAD_TITLE: MvnCodeFE;
  DOWNLOAD_ALL_TITLE: MvnCodeFE;
  PROPERTIES_TITLE: MvnCodeFE;
  ALL_TITLE: MvnCodeFE;
  IN_PROGRESS_TITLE: MvnCodeFE;
  COMPLETED_TITLE: MvnCodeFE;
  UNREAD_TITLE: MvnCodeFE;
  ON_BEHALF_OF_TITLE: MvnCodeFE;
  CATEGORY_TITLE: MvnCodeFE;
  ASSIGN_TO_TITLE: MvnCodeFE;
  PRIORITY_TITLE: MvnCodeFE;
  SAVE_TITLE: MvnCodeFE;
  SAVE_DRAFT_TITLE: MvnCodeFE;
  PROPOSE_TITLE: MvnCodeFE;
  SUBMIT_TITLE: MvnCodeFE;
  COMMENT_TITLE: MvnCodeFE;
  EDIT_TITLE: MvnCodeFE;
  CANCEL_TITLE: MvnCodeFE;
  DELETE_TITLE: MvnCodeFE;
  QUESTION_TEAM_TITLE: MvnCodeFE;
  QUESTION_DRAFTER_TITLE: MvnCodeFE;
  QUESTION_SUBMITTER_TITLE: MvnCodeFE;
  ANSWER_TEAM_TITLE: MvnCodeFE;
  EXPERT_TITLE: MvnCodeFE;
  ANSWER_COORDINATOR_TITLE: MvnCodeFE;
  ADD_TEAM_TITLE: MvnCodeFE;
  ADD_CATEGORY_TITLE: MvnCodeFE;
  ADD_COORDINATOR_TITLE: MvnCodeFE;
  TEAM_NAME_TITLE: MvnCodeFE;
  TEAM_USER_TITLE: MvnCodeFE;
  ADD_MEMBER_TITLE: MvnCodeFE;
  QUESTION_LIMIT_TITLE: MvnCodeFE;
  LIMIT_TITLE: MvnCodeFE;
  CATEGORY_NAME_TITLE: MvnCodeFE;
  EDIT_TEAM_TITLE: MvnCodeFE;
  MARK_AS_COMPLETE_TITLE: MvnCodeFE;
  USER_TITLE: MvnCodeFE;
  LOW_TITLE: MvnCodeFE;
  MEDIUM_TITLE: MvnCodeFE;
  HIGH_TITLE: MvnCodeFE;
  TOTAL_TITLE: MvnCodeFE;
  CATEGORY_EDIT_TITLE: MvnCodeFE;
  ANSWER_COORDINATOR_EDIT_TITLE: MvnCodeFE;
  QUESTION_TEAM_EDIT_TITLE: MvnCodeFE;
  PRIORITY_EDIT_TITLE: MvnCodeFE;
  BACK_TO_LIST_TITLE: MvnCodeFE;
  CURRENT_WORKFLOW_TITLE: MvnCodeFE;

  SELECT_OPTION_PLACEHOLDER: MvnCodeFE;
  ENTER_CONTENT_PLACEHOLDER: MvnCodeFE;
  SELECT_LIMIT_PLACEHOLDER: MvnCodeFE;
  ENTER_TEAM_NAME_PLACEHOLDER: MvnCodeFE;
  ENTER_CATEGORY_NAME_PLACEHOLDER: MvnCodeFE;

  DOWNLOAD_ALL_START: MvnCodeFE;
  DOWNLOAD_ALL_FAIL: MvnCodeFE;
  QUESTION_DRAFT_SUCCESS: MvnCodeFE;
  QUESTION_DRAFT_FAIL: MvnCodeFE;
  QUESTION_PROPOSE_SUCCESS: MvnCodeFE;
  QUESTION_PROPOSE_FAIL: MvnCodeFE;
  QUESTION_SUBMIT_SUCCESS: MvnCodeFE;
  QUESTION_SUBMIT_FAIL: MvnCodeFE;
  ANSWER_DRAFT_SUCCESS: MvnCodeFE;
  ANSWER_DRAFT_FAIL: MvnCodeFE;
  ANSWER_PROPOSE_SUCCESS: MvnCodeFE;
  ANSWER_PROPOSE_FAIL: MvnCodeFE;
  ANSWER_SUBMIT_SUCCESS: MvnCodeFE;
  ANSWER_SUBMIT_FAIL: MvnCodeFE;
  FILE_ATTACH_SUCCESS: MvnCodeFE;
  FILE_ATTACH_FAIL: MvnCodeFE;
  DOCUMENT_LINK_SUCCESS: MvnCodeFE;
  DOCUMENT_LINK_FAIL: MvnCodeFE;
  CATEGORY_CHANGE_SUCCESS: MvnCodeFE;
  CATEGORY_CHANGE_FAIL: MvnCodeFE;
  PRIORITY_CHANGE_SUCCESS: MvnCodeFE;
  PRIORITY_CHANGE_FAIL: MvnCodeFE;
  MARK_AS_COMPLETE_SUCCESS: MvnCodeFE;
  MARK_AS_COMPLETE_FAIL: MvnCodeFE;
  QUESTION_DELETE_SUCCESS: MvnCodeFE;
  QUESTION_DELETE_FAIL: MvnCodeFE;
  QUESTION_TEAM_ADD_SUCCESS: MvnCodeFE;
  QUESTION_TEAM_ADD_FAIL: MvnCodeFE;
  QUESTION_TEAM_EDIT_SUCCESS: MvnCodeFE;
  QUESTION_TEAM_EDIT_FAIL: MvnCodeFE;
  QUESTION_TEAM_DELETE_SUCCESS: MvnCodeFE;
  QUESTION_TEAM_DELETE_FAIL: MvnCodeFE;
  CATEGORY_ADD_SUCCESS: MvnCodeFE;
  CATEGORY_ADD_FAIL: MvnCodeFE;
  CATEGORY_EDIT_SUCCESS: MvnCodeFE;
  CATEGORY_EDIT_FAIL: MvnCodeFE;
  CATEGORY_DELETE_SUCCESS: MvnCodeFE;
  CATEGORY_DELETE_FAIL: MvnCodeFE;
  COORDINATOR_ADD_SUCCESS: MvnCodeFE;
  COORDINATOR_ADD_FAIL: MvnCodeFE;
  COORDINATOR_EDIT_SUCCESS: MvnCodeFE;
  COORDINATOR_EDIT_FAIL: MvnCodeFE;
  COORDINATOR_DELETE_SUCCESS: MvnCodeFE;
  COORDINATOR_DELETE_FAIL: MvnCodeFE;
  TEAM_NAME_EDIT_SUCCESS: MvnCodeFE;
  TEAM_NAME_EDIT_FAIL: MvnCodeFE;
  TEAM_DELETE_SUCCESS: MvnCodeFE;
  TEAM_DELETE_FAIL: MvnCodeFE;
} = {
  // Description
  LIMIT_USAGE_DESCRIPTION: {
    ko: "“{priority}” 중요도 질문 {limit} 중 {usage} 이 사용되었습니다.",
    en: "{usage} of {limit} “{priority}” priority questions have been used.",
  },
  DELETE_THREAD_DESCRIPTION_1: {
    ko: "선택된 질문은 삭제되며 복구할 수 없습니다.",
    en: "The selected question(s) will be deleted permanently.",
  },
  DELETE_THREAD_DESCRIPTION_2: {
    ko: "진행하시겠습니까?",
    en: "Are you sure you want to delete the question(s)?",
  },
  DELETE_TEAM_DESCRIPTION: { ko: "{name}(을/를) 삭제하겠습니까?", en: "Are you sure you want to delete {name}?" },
  EMPTY_QNA_DESCRIPTION: { ko: "질문 추가 버튼을 눌러 추가해 주세요.", en: "Please click the Add Question button below to add" },
  EMPTY_QNA_SETTING_DESCRIPTION: {
    ko: "아래의 버튼을 눌러 워크플로우를\n먼저 설정해주세요.",
    en: "Please click the button below\nto set up the workflow first.",
  },
  MARK_AS_COMPLETE_DESCRIPTION_1: {
    ko: "선택된 Q&A는 완료 상태로 변경됩니다.",
    en: "The selected question(s) will be marked as complete.",
  },
  MARK_AS_COMPLETE_DESCRIPTION_2: { ko: "변경하시겠습니까?", en: "Are you sure you want to complete the question(s)?" },
  QUESTION_DRAFTER_DESCRIPTION: {
    ko: "질문 초안을 작성합니다. 작성된 초안은 검토를 위해 질문 제출자에게 전달됩니다.",
    en: "Draft questions, which are proposed to question submitters for review.",
  },
  QUESTION_SUBMITTER_DESCRIPTION: {
    ko: "질문을 작성하거나 질문 초안을 검토 및 수정하여 답변 팀에게 제출합니다.",
    en: "Review and edit questions from drafters or make their own questions for submission to the answer team.",
  },
  EXPERT_DESCRIPTION: {
    ko: "배정 받은 질문을 보고 답변합니다. ",
    en: "View and answer assigned questions.",
  },
  ANSWER_COORDINATOR_DESCRIPTION: {
    ko: "전문가에게 질문을 배정하고 답변을 검토 및 수정하여 질문 팀에게 제출합니다.",
    en: "Assign questions to experts. Review, edit, and submit answers to questions team.",
  },
  ONLY_MANAGE_QNA_WARNING_DESCRIPTION: {
    ko: "Manage Users 미선택시 Q&A의 기능이 일부 제한됩니다.",
    en: "If 'Manage Users' is not selected, the Q&A Setting will be restricted.",
  },

  // Title
  QNA_DETAIL_TITLE: { ko: "제목", en: "Title" },
  QNA_SETTINGS_TITLE: { ko: "Q&A 설정", en: "Q&A Settings" },
  EMPTY_QNA_TITLE: { ko: "등록된 Q&A가 없습니다.", en: "No Q&A yet." },
  EMPTY_QNA_SETTING: { ko: "등록된 Q&A 세팅이 없습니다.", en: "No Q&A Settings yet." },
  CREATE_QUESTION_TITLE: {
    ko: "Create New Question",
    en: "Create New Question",
  },
  ADD_QUESTION_TITLE: { ko: "질문 추가", en: "Add Question" },
  NEW_QUESTION_TITLE: { ko: "질문 추가", en: "New Question" },
  DOWNLOAD_TITLE: { ko: "다운로드", en: "Download" },
  DOWNLOAD_ALL_TITLE: { ko: "전체 다운로드", en: "Download All" },
  ALL_TITLE: { ko: "전체", en: "All" },
  IN_PROGRESS_TITLE: { ko: "진행 중", en: "In Progress" },
  COMPLETED_TITLE: { ko: "완료", en: "Complete" },
  UNREAD_TITLE: { ko: "읽지 않음", en: "Unread" },
  PROPERTIES_TITLE: { ko: "속성 정보", en: "Properties" },

  ON_BEHALF_OF_TITLE: { ko: "대리 작성", en: "On behalf of" },
  CATEGORY_TITLE: { ko: "카테고리", en: "Category" },
  ASSIGN_TO_TITLE: { ko: "할당", en: "Assign to" },
  PRIORITY_TITLE: { ko: "중요도", en: "Priority" },
  SAVE_TITLE: { ko: "저장", en: "Save" },
  SAVE_DRAFT_TITLE: { ko: "초안 저장", en: "Save Draft" },
  PROPOSE_TITLE: { ko: "제안", en: "Propose" },
  SUBMIT_TITLE: { ko: "제출", en: "Submit" },
  COMMENT_TITLE: { ko: "댓글", en: "Comment" },
  EDIT_TITLE: { ko: "편집", en: "Edit" },
  CANCEL_TITLE: { ko: "취소", en: "Cancel" },
  DELETE_TITLE: { ko: "삭제", en: "Delete" },
  QUESTION_TEAM_TITLE: { ko: "질문 팀", en: "Question Team" },
  QUESTION_DRAFTER_TITLE: { ko: "질문 작성자", en: "Question drafter" },
  QUESTION_SUBMITTER_TITLE: { ko: "질문 제출자", en: "Question submitter" },
  ANSWER_TEAM_TITLE: { ko: "답변 팀", en: "Answer Team" },
  EXPERT_TITLE: { ko: "전문가", en: "Expert" },
  ANSWER_COORDINATOR_TITLE: { ko: "답변 제출자", en: "Answer coordinator" },
  ADD_TEAM_TITLE: { ko: "팀 추가", en: "Add Team" },
  ADD_CATEGORY_TITLE: { ko: "카테고리 추가", en: "Add Category" },
  ADD_COORDINATOR_TITLE: { ko: "제출자 추가", en: "Add Coordinator" },
  TEAM_NAME_TITLE: { ko: "팀 이름", en: "Team name" },
  TEAM_USER_TITLE: { ko: "팀 사용자", en: "Team Users" },
  ADD_MEMBER_TITLE: { ko: "멤버 추가", en: "Add Member" },
  QUESTION_LIMIT_TITLE: { ko: "질문 제한", en: "Question Limit" },
  LIMIT_TITLE: { ko: "제한", en: "Limit" },
  CATEGORY_NAME_TITLE: { ko: "카테고리 이름", en: "Category name" },
  EDIT_TEAM_TITLE: { ko: "팀 수정", en: "Edit Team" },
  MARK_AS_COMPLETE_TITLE: { ko: "완료 상태로 변경", en: "Mark Complete" },
  USER_TITLE: { ko: "사용자", en: "User" },
  LOW_TITLE: { ko: "하", en: "Low" },
  MEDIUM_TITLE: { ko: "중", en: "Medium" },
  HIGH_TITLE: { ko: "상", en: "High" },
  TOTAL_TITLE: { ko: "합계", en: "Total" },
  CATEGORY_EDIT_TITLE: { ko: "카테고리 수정", en: "Edit Category" },
  ANSWER_COORDINATOR_EDIT_TITLE: { ko: "답변 제출자 수정", en: "Edit Answer Coordinator" },
  QUESTION_TEAM_EDIT_TITLE: { ko: "질문 팀 수정", en: "Edit Question Team" },
  PRIORITY_EDIT_TITLE: { ko: "중요도 수정", en: "Edit Priority" },
  BACK_TO_LIST_TITLE: { ko: "목록으로", en: "Back" },
  CURRENT_WORKFLOW_TITLE: { ko: "현재 워크플로우", en: "Current Workflow" },

  SELECT_OPTION_PLACEHOLDER: { ko: "옵션 선택", en: "Select Option" },
  ENTER_CONTENT_PLACEHOLDER: { ko: "내용 입력", en: "Enter content" },
  SELECT_LIMIT_PLACEHOLDER: { ko: "제한 선택", en: "Select limit" },
  ENTER_TEAM_NAME_PLACEHOLDER: { ko: "팀 이름 입력", en: "Enter team name" },
  ENTER_CATEGORY_NAME_PLACEHOLDER: { ko: "카테고리 이름 입력", en: "Enter category name" },

  DOWNLOAD_ALL_START: { ko: "다운로드가 시작되었습니다.", en: "Download has started." },
  DOWNLOAD_ALL_FAIL: { ko: "다운로드에 실패하였습니다.", en: "Failed to download." },
  QUESTION_DRAFT_SUCCESS: { ko: "질문 초안이 작성되었습니다.", en: "The question has been drafted." },
  QUESTION_DRAFT_FAIL: { ko: "질문 초안 작성에 실패하였습니다.", en: "Failed to draft the question." },
  QUESTION_PROPOSE_SUCCESS: { ko: "질문이 제안되었습니다.", en: "The question has been proposed." },
  QUESTION_PROPOSE_FAIL: { ko: "질문 제안에 실패하였습니다.", en: "Failed to propose the question." },
  QUESTION_SUBMIT_SUCCESS: { ko: "질문이 제출되었습니다.", en: "The question has been submitted." },
  QUESTION_SUBMIT_FAIL: { ko: "질문 제출에 실패하였습니다.", en: "Failed to submit the question." },
  ANSWER_DRAFT_SUCCESS: { ko: "답변 초안이 작성되었습니다.", en: "The answer has been drafted." },
  ANSWER_DRAFT_FAIL: { ko: "답변 초안 작성에 실패하였습니다. ", en: "Failed to draft the answer." },
  ANSWER_PROPOSE_SUCCESS: { ko: "답변이 제안되었습니다.", en: "The answer has been proposed." },
  ANSWER_PROPOSE_FAIL: { ko: "답변 제안에 실패하였습니다.", en: "Failed to propose the answer." },
  ANSWER_SUBMIT_SUCCESS: { ko: "답변이 제출되었습니다.", en: "The answer has been submitted." },
  ANSWER_SUBMIT_FAIL: { ko: "답변 제출에 실패하였습니다.", en: "Failed to submit the answer." },
  FILE_ATTACH_SUCCESS: { ko: "파일이 첨부 되었습니다.", en: "The file has been attached." },
  FILE_ATTACH_FAIL: { ko: "파일첨부에 실패하였습니다.", en: "Failed to attach the file." },
  DOCUMENT_LINK_SUCCESS: { ko: "문서가 연결되었습니다.", en: "The document has been linked." },
  DOCUMENT_LINK_FAIL: { ko: "문서 연결에 실패하였습니다.", en: "Failed to link the document." },
  CATEGORY_CHANGE_SUCCESS: { ko: "카테고리가 변경되었습니다.", en: "The category has been changed." },
  CATEGORY_CHANGE_FAIL: { ko: "카테고리 변경에 실패하였습니다.", en: "Failed to change the category." },
  PRIORITY_CHANGE_SUCCESS: { ko: "중요도가 변경되었습니다.", en: "The priority has been changed." },
  PRIORITY_CHANGE_FAIL: { ko: "중요도 변경에 실패하였습니다. ", en: "Failed to change the priority." },
  MARK_AS_COMPLETE_SUCCESS: { ko: "완료로 변경하였습니다.", en: "The question(s) has been marked complete." },
  MARK_AS_COMPLETE_FAIL: { ko: "완료 변경에 실패하였습니다.", en: "Failed to mark the question(s) complete." },
  QUESTION_DELETE_SUCCESS: { ko: "질문이 삭제되었습니다.", en: "The question(s) has been deleted." },
  QUESTION_DELETE_FAIL: { ko: "질문 삭제에 실패하였습니다.", en: "Failed to delete the question(s)." },
  QUESTION_TEAM_ADD_SUCCESS: { ko: "질문 팀이 추가되었습니다.", en: "The question team has been added." },
  QUESTION_TEAM_ADD_FAIL: { ko: "질문 팀 추가에 실패하였습니다.", en: "Failed to add the question team." },
  QUESTION_TEAM_EDIT_SUCCESS: { ko: "질문 팀이 수정되었습니다.", en: "The question team has been edited." },
  QUESTION_TEAM_EDIT_FAIL: { ko: "질문 팀 수정에 실패하였습니다.", en: "Failed to edit the question team." },
  QUESTION_TEAM_DELETE_SUCCESS: { ko: "질문 팀이 삭제되었습니다.", en: "The question team has been deleted. " },
  QUESTION_TEAM_DELETE_FAIL: { ko: "질문 팀 삭제에 실패하였습니다.", en: "Failed to delete the question team." },
  CATEGORY_ADD_SUCCESS: { ko: "카테고리가 추가되었습니다.", en: "The category has been added. " },
  CATEGORY_ADD_FAIL: { ko: "카테고리를 생성하지 못했습니다.", en: "Failed to add a category. " },
  CATEGORY_EDIT_SUCCESS: { ko: "카테고리 {name}이 수정되었습니다.", en: "The category {name} has been edited." },
  CATEGORY_EDIT_FAIL: { ko: "카테고리 수정에 실패하였습니다. ", en: "Failed to edit the category. " },
  CATEGORY_DELETE_SUCCESS: { ko: "카테고리가 삭제되었습니다.", en: "The category has been deleted. " },
  CATEGORY_DELETE_FAIL: { ko: "카테고리 삭제에 실패하였습니다. ", en: "Failed to delete the category. " },
  COORDINATOR_ADD_SUCCESS: { ko: "답변 제출자가 추가되었습니다.", en: "The coordinator has been added." },
  COORDINATOR_ADD_FAIL: { ko: "답변 제출자를 추가하지 못했습니다.", en: "Failed to add a coordinator." },
  COORDINATOR_EDIT_SUCCESS: { ko: "답변 제출자가 수정되었습니다.", en: "The coordinator {name} has been edited" },
  COORDINATOR_EDIT_FAIL: { ko: "답변 제출자를 수정하지 못했습니다.", en: "Failed to edit a coordinator." },
  COORDINATOR_DELETE_SUCCESS: { ko: "답변 제출자가 삭제되었습니다.", en: "The coordinator {name} has been deleted" },
  COORDINATOR_DELETE_FAIL: { ko: "답변 제출자를 삭제하지 못했습니다.", en: "Failed to delete a coordinator." },
  TEAM_NAME_EDIT_SUCCESS: { ko: "Team {name}이 수정되었습니다.", en: "The team {name} has been edited. " },
  TEAM_NAME_EDIT_FAIL: { ko: "Team 수정에 실패하였습니다.", en: "Failed to edit the team." },
  TEAM_DELETE_SUCCESS: { ko: "Team {name}이 삭제되었습니다.", en: "The team {name} has been deleted. " },
  TEAM_DELETE_FAIL: { ko: "Team 삭제에 실패하였습니다.", en: "Failed to delete the team." },
};

MvnCodeInitiate(VDR_ROOM_QUESTION_LANG);
