import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_CATEGORY_CODE: {
  CDMS_STUDY_CATEGORY_NO_DELETE_MAPPING_SUBJECT: MvnCodeBE;
  CDMS_STUDY_CATEGORY_NO_CHANGE: MvnCodeBE;
  CDMS_STUDY_CATEGORY_NOT_FOUND: MvnCodeBE;
  CDMS_STUDY_CATEGORY_MAX_EXCEED: MvnCodeBE;
} = {
  CDMS_STUDY_CATEGORY_NO_DELETE_MAPPING_SUBJECT: {
    status: 409,
    ko: "Mapping된 subject가 존재하는 column은 삭제할 수 없습니다.",
    en: "You cannot delete column that subject mapped already.",
  },
  CDMS_STUDY_CATEGORY_NO_CHANGE: {
    status: 400,
    ko: "변경사항이 없습니다.",
    en: "There are no changes.",
  },
  CDMS_STUDY_CATEGORY_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study Category 정보를 찾을 수 없습니다.",
    en: "Requested study category could not be found.",
  },
  CDMS_STUDY_CATEGORY_MAX_EXCEED: {
    status: 400,
    ko: "최대 {count}개까지 추가 가능합니다.",
    en: "You can only add up to {count} notifications.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CATEGORY_CODE);
