import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CONVERTER_STUDY_DATA_FLOW_CODE: {
  DATASET_REFERENCED: MvnCodeBE;
} = {
  DATASET_REFERENCED: {
    status: 400,
    ko: "해당 파일은 다른 흐름에서 참조하고 있으므로 삭제가 불가능합니다.",
    en: "This file cannot be deleted as it is referenced in another flow.",
  },
};

MvnCodeInitiate(CONVERTER_STUDY_DATA_FLOW_CODE);
