import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_CATEGORY_VALUE_CODE: {
  CDMS_STUDY_CATEGORY_VALUE_NOT_FOUND: MvnCodeBE;
  CDMS_STUDY_CATEGORY_VALUE_MAX_EXCEED: MvnCodeBE;
} = {
  CDMS_STUDY_CATEGORY_VALUE_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study Category Value 정보를 찾을 수 없습니다.",
    en: "Requested study category value could not be found.",
  },
  CDMS_STUDY_CATEGORY_VALUE_MAX_EXCEED: {
    status: 400,
    ko: "최대 {count}개 value까지 추가 가능합니다.",
    en: "You can only add up to {count} values.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CATEGORY_VALUE_CODE);
