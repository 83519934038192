import { AdminNoticeLang, AdminNoticeType, MavenProductServiceWithClinicalCloud } from "./Notice.dto";
import { readableMavenProductService } from "../common/common.conv";
import { LoLang, MavenService } from "../common/common.types";

export const readableNoticeType = (type: AdminNoticeType): string => {
  switch (type) {
    case AdminNoticeType.SystemOperation:
      return "System Check";

    case AdminNoticeType.ResolvedIncident:
      return "Resolve Incident";

    default:
      return "(Unknown)";
  }
};

export const readableMavenProductServiceWithClinicalCloud = (
  type: MavenProductServiceWithClinicalCloud,
  defaultValue?: MavenProductServiceWithClinicalCloud | string
): string => {
  switch (type) {
    case MavenService.MavenCDMS:
    case MavenService.MavenDocs:
    case MavenService.MavenSafety:
    case MavenService.MavenAuth:
    case MavenService.MavenBuilder:
    case MavenService.MavenVDR:
    case MavenService.MavenTMF:
      return readableMavenProductService(type);

    case MavenService.MavenClinicalCloud:
      return "ALL";

    default:
      return defaultValue ?? "(Unknown)";
  }
};

export const isAdminNoticeLang = (lang: LoLang | string): lang is AdminNoticeLang => {
  switch (lang) {
    case LoLang.KO:
    case LoLang.EN:
      return true;
    default:
      return false;
  }
};
